import * as React from 'react';
import InfoIconUI from '../InfoIcon/InfoIconUI';
var showNotAvailableText = function (value, notAvailableText) {
    return value === null && notAvailableText;
};
var getValueText = function (value, notAvailableText) {
    return showNotAvailableText(value, notAvailableText) ? notAvailableText : value;
};
var getValueClassName = function (value, notAvailableText) {
    var valueClassName = 'field-value';
    if (showNotAvailableText(value, notAvailableText)) {
        valueClassName += ' not-available';
    }
    return valueClassName;
};
export var LineItemUI = function (props) {
    var valueClassName = getValueClassName(props.value, props.notAvailableText);
    var valueText = getValueText(props.value, props.notAvailableText);
    return valueText && props.label ? (React.createElement("div", { className: "line-item", id: props.id },
        React.createElement("span", { className: "field-label" }, props.label.replace('&amp;', '&')),
        props.showToolTip ? (React.createElement("div", { className: "adjusted-cashdown-container" },
            React.createElement(InfoIconUI, { onIconClick: props.onClickEvent }),
            React.createElement("span", { className: valueClassName + " adjusted-cashdown-text" }, valueText))) : (React.createElement("span", { className: valueClassName }, valueText)))) : null;
};
export var GroupLineItemUI = function (_a) {
    var label = _a.label, value = _a.value, notAvailableText = _a.notAvailableText, id = _a.id, showSeparator = _a.showSeparator;
    var valueClassName = getValueClassName(value, notAvailableText);
    var valueText = getValueText(value, notAvailableText);
    return valueText ? (React.createElement(React.Fragment, null,
        showSeparator && React.createElement("hr", null),
        React.createElement("div", { className: "line-item sub-total", id: id },
            React.createElement("label", { className: "field-label" }, label),
            React.createElement("span", { className: valueClassName }, valueText)))) : null;
};
