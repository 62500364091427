var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Component } from 'react';
import * as PropTypes from 'prop-types';
import { PriceLabelUI as PriceLabel } from '../../components/PriceLabel/PriceLabelUI';
import CardActionButtonUI from '../../components/CardActionButton/CardActionButtonUI';
import MenuProductImage from './MenuProductImage';
import MenuProductDetails from './MenuProductDetails';
var CardActionButton = CardActionButtonUI;
var MenuProductCardUI = /** @class */ (function (_super) {
    __extends(MenuProductCardUI, _super);
    function MenuProductCardUI(props) {
        return _super.call(this, props) || this;
    }
    MenuProductCardUI.prototype.handleClick = function () {
    };
    MenuProductCardUI.prototype.handleAddClick = function () {
        if (this.props.onAddClick) {
            this.props.onAddClick();
        }
    };
    MenuProductCardUI.prototype.getCardImage = function (showCardImage) {
        if (!showCardImage) {
            return null;
        }
        var _a = this.props, _b = _a.media, media = _b === void 0 ? [''] : _b, _c = _a.imagePlaceHolder, imagePlaceHolder = _c === void 0 ? '' : _c;
        return (React.createElement(MenuProductImage, { imageUrl: media[0].url, imagePlaceHolder: imagePlaceHolder }));
    };
    MenuProductCardUI.prototype.handleVideoLinkClick = function () {
        if (this.props.onVideoLinkClick) {
            this.props.onVideoLinkClick();
        }
    };
    MenuProductCardUI.prototype.getVideoLink = function (showVideoLink) {
        var _this = this;
        if (!showVideoLink) {
            return null;
        }
        var media = this.props.media || [''];
        return (React.createElement("span", { className: "watch-video-link", onClick: function () { _this.handleVideoLinkClick(); } },
            React.createElement("a", null, "Watch Video")));
    };
    MenuProductCardUI.prototype.handleViewDetailsClick = function (id) {
        this.props.onToggleDetailsView(id, !this.props.isShowDetailsOpen, this.props.categoryCode);
    };
    MenuProductCardUI.prototype.handleTermChange = function (term) {
        if (this.props.onTermChange) {
            this.props.onTermChange(term);
        }
    };
    MenuProductCardUI.prototype.handleMileageChange = function (mileage) {
        if (this.props.onMileageChange) {
            this.props.onMileageChange(mileage);
        }
    };
    MenuProductCardUI.prototype.handleNoMilesSelected = function () {
        return this.props.selectedMileage === null ?
            React.createElement("div", null, "Select a mileage above to add this vehicle protection product.")
            :
                '';
    };
    MenuProductCardUI.prototype.createButtonText = function (isRated, isShowDetailsOpen, terms) {
        if (isRated === void 0) { isRated = false; }
        if (isShowDetailsOpen === void 0) { isShowDetailsOpen = false; }
        if (terms === void 0) { terms = []; }
        var buttonText = 'Add';
        if (isRated && !isShowDetailsOpen && terms.length) {
            buttonText = 'Choose Terms';
        }
        return buttonText;
    };
    MenuProductCardUI.prototype.showCallForPricing = function (errorMessage, totalPrice) {
        if (errorMessage === void 0) { errorMessage = ''; }
        if (totalPrice === void 0) { totalPrice = null; }
        var callForPricing = false;
        // tslint:disable-next-line: no-boolean-literal-compare
        if ((errorMessage || !totalPrice) && this.props.isPriceEnable === false) {
            callForPricing = true;
        }
        return callForPricing;
    };
    MenuProductCardUI.prototype.whatHandlerUseOnClickButton = function (terms) {
        if (terms === void 0) { terms = []; }
        return !this.props.isShowDetailsOpen && !this.props.isSelected && this.props.isRated && terms.length > 0;
    };
    MenuProductCardUI.prototype.render = function () {
        var _this = this;
        var _a = this.props, cardHeading = _a.cardHeading, errorMessage = _a.errorMessage, secondaryText = _a.secondaryText, showCardImage = _a.showCardImage, showVideoLink = _a.showVideoLink, fullDetails = _a.fullDetails, isShowDetailsOpen = _a.isShowDetailsOpen, isRated = _a.isRated, terms = _a.terms, isSelected = _a.isSelected, plans = _a.plans, totalPrice = _a.totalPrice, dealerPhone = _a.dealerPhone, vppMonthlyPaymentLabeled = _a.vppMonthlyPaymentLabeled, isVppMonthlyPaymentEnabled = _a.isVppMonthlyPaymentEnabled, isMenuPricingEnabled = _a.isMenuPricingEnabled, isCashOffer = _a.isCashOffer, offerType = _a.offerType, isMenuPaymentsDisclaimerEnabled = _a.isMenuPaymentsDisclaimerEnabled;
        var cardImage = this.getCardImage(showCardImage);
        var videoLink = this.getVideoLink(showVideoLink);
        var className = isRated ? 'dr-ui-menu-product-card dr-ui-rated-menu-product' : 'dr-ui-menu-product-card';
        var spinner = this.props.children && this.props.children.key ?
            React.createElement("div", { key: this.props.children.key + Math.random() }, this.props.children) : '';
        var buttonText = this.createButtonText(isRated, isShowDetailsOpen, terms);
        var handleButtonClick = this.whatHandlerUseOnClickButton(terms) ?
            function () { return _this.handleViewDetailsClick(_this.props.rawId); } :
            function () { return _this.handleAddClick(); };
        var actionBtn = (React.createElement(CardActionButton, { isSelected: isSelected, buttonText: buttonText, onClick: handleButtonClick, disabled: this.props.selectedMileage === null ? true : false }));
        var priceLabel = (React.createElement(PriceLabel, { className: "dr-ui-package-total", price: totalPrice, vppMonthlyPaymentLabeled: vppMonthlyPaymentLabeled, hasError: this.showCallForPricing(errorMessage, totalPrice), dealerPhone: dealerPhone, isVppMonthlyPaymentEnabled: isVppMonthlyPaymentEnabled, isCashOffer: isCashOffer, offerType: this.props.offerType, isMenuPaymentsDisclaimerEnabled: this.props.isMenuPaymentsDisclaimerEnabled }));
        var header = (React.createElement("div", { className: "header" }, cardHeading));
        var coverageName = this.props.coverageName;
        var secondText = (React.createElement("div", { className: "secondary" },
            React.createElement("div", { className: "secondary-text" },
                isShowDetailsOpen ? null : secondaryText,
                React.createElement(MenuProductDetails, { errorMessage: errorMessage, fullDetails: fullDetails, isShowDetailsOpen: isShowDetailsOpen, isRated: isRated, cardId: this.props.id, plans: plans, terms: terms, mileages: this.props.mileages, onTermChange: function (term) {
                        // There's a bug that causes the term change to trigger twice, but we only
                        // want to trigger it if the term actually changes:
                        if (term !== _this.props.selectedTerm) {
                            _this.handleTermChange(term);
                        }
                    }, onMileageChange: function (mileage) {
                        // There's a bug that causes the mileage change to trigger twice, but we only
                        // want to trigger it if the mileage actually changes:
                        if (mileage !== _this.props.selectedMileage) {
                            _this.handleMileageChange(mileage);
                        }
                    }, selectedTerm: this.props.selectedTerm, selectedMileage: this.props.selectedMileage, selectedDeductible: this.props.selectedDeductible, mapUnlimitedToMiles: { Unlimited: null }, coverageName: coverageName }),
                this.handleNoMilesSelected())));
        var details = (React.createElement("div", { className: (fullDetails || isRated ? 'expandable' : '') + " action-buttons" },
            React.createElement("div", { className: "" + (fullDetails || isRated ? 'view-details-button' : 'hidden') },
                React.createElement("a", { className: "fa " + (isShowDetailsOpen ? 'fa-minus-circle' : 'fa-plus-circle'), "aria-hidden": "true" },
                    React.createElement("span", { className: "viewDetailsLink", onClick: function () { _this.handleViewDetailsClick(_this.props.rawId); } }, isShowDetailsOpen ? ' Hide Details' : ' View Details')))));
        var media = (React.createElement("div", { className: "card-image" },
            cardImage,
            videoLink));
        var classNameForPriceBox = isVppMonthlyPaymentEnabled
            ? 'pull-left dr-ui-vpp-price-box'
            : 'pull-left';
        // TODO: Refactor later to move showPrice out
        var showPrice = function (isMobile, isMenuPricingEnabled) {
            var priceElement;
            if (isMenuPricingEnabled) {
                switch (isMobile) {
                    case true:
                        priceElement = (React.createElement("div", { className: classNameForPriceBox }, priceLabel));
                        break;
                    default:
                        priceElement = priceLabel;
                        break;
                }
            }
            return priceElement;
        };
        return (React.createElement("div", { id: this.props.id, className: className, onClick: function () { _this.handleClick(); } },
            React.createElement("div", { className: "d-md-none card-text" },
                header,
                React.createElement("div", { className: "dr-ui-product-container-media" }, media),
                React.createElement("div", { className: "dr-ui-product-container-text" },
                    secondText,
                    spinner),
                React.createElement("div", { className: "row dr-ui-product-container-price-and-add" },
                    showPrice(true, isMenuPricingEnabled),
                    React.createElement("div", { className: "pull-right" }, actionBtn)),
                React.createElement("div", { className: "row dr-ui-product-container-expand-details" }, details)),
            React.createElement("div", { className: "d-sm-none card-text" },
                React.createElement("div", { className: "dr-ui-product-container" },
                    React.createElement("div", { className: "" }, media),
                    React.createElement("div", { className: "dr-ui-product-container-text" },
                        header,
                        secondText,
                        spinner,
                        details),
                    React.createElement("div", { className: "dr-ui-product-container-price-add-btn" },
                        showPrice(false, isMenuPricingEnabled),
                        actionBtn)))));
    };
    MenuProductCardUI.propTypes = {
        cardHeading: PropTypes.string,
        cardState: PropTypes.string,
        id: PropTypes.string,
        isExpanded: PropTypes.bool,
        isSelected: PropTypes.bool,
        media: PropTypes.any,
        secondaryText: PropTypes.string,
        showCardImage: PropTypes.bool,
        showVideoLink: PropTypes.bool,
        fullDetails: PropTypes.string,
        onToggleDetailsView: PropTypes.func,
        isShowDetailsOpen: PropTypes.bool,
        rawId: PropTypes.string,
        isRated: PropTypes.bool,
        categoryCode: PropTypes.string
    };
    MenuProductCardUI.defaultProps = {
        cardHeading: '',
        cardState: '',
        id: '',
        isSelected: false,
        media: '',
        secondaryText: '',
        showCardImage: true,
        showVideoLink: true,
        fullDetails: '',
        isRated: true
    };
    return MenuProductCardUI;
}(Component));
export default MenuProductCardUI;
