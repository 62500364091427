// TODO: Move this under MenuProductCard
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Component } from 'react';
import * as PropTypes from 'prop-types';
var ProductPackageCardGroupUI = /** @class */ (function (_super) {
    __extends(ProductPackageCardGroupUI, _super);
    function ProductPackageCardGroupUI() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProductPackageCardGroupUI.prototype.handleClick = function (value) {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };
    ProductPackageCardGroupUI.prototype.buildProductPackageCard = function (planObject, index, cardId) {
        var _this = this;
        return (React.createElement("div", { key: index, className: "dr-product-package-card" },
            React.createElement("div", { className: "card-header", onClick: function () { _this.handleClick(planObject.description); } },
                React.createElement("div", { className: "card-title" },
                    planObject.description,
                    React.createElement("br", null),
                    React.createElement("span", null, "Plan total $--")),
                React.createElement("div", { className: "header-radio-button" },
                    React.createElement("div", { className: "product-package-radio " + (planObject.isSelected ? 'isChecked' : '') }))),
            React.createElement("div", { className: "card-body" },
                "Month from",
                React.createElement("br", null),
                "Miles from",
                React.createElement("br", null),
                "Eligibility",
                React.createElement("br", null),
                "Odometer Eligibility",
                React.createElement("br", null))));
    };
    ProductPackageCardGroupUI.prototype.buildProductPackageCardGroup = function (plans, cardId) {
        var _this = this;
        return plans.map(function (plan, index) { return _this.buildProductPackageCard(plan, index, cardId); });
    };
    ProductPackageCardGroupUI.prototype.render = function () {
        var _a = this.props, plans = _a.plans, cardId = _a.cardId;
        var ProductPackageCardGroup = this.buildProductPackageCardGroup(plans, cardId);
        return (React.createElement("div", { className: "product-package-card-group" }, ProductPackageCardGroup));
    };
    ProductPackageCardGroupUI.propTypes = {
        plansArray: PropTypes.array,
        cardId: PropTypes.string
    };
    return ProductPackageCardGroupUI;
}(Component));
export default ProductPackageCardGroupUI;
