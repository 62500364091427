export var ROUTE_CREDIT_APP = 'ROUTE_CREDIT_APP';
export var ROUTE_CREDIT_RESULTS = 'ROUTE_CREDIT_RESULTS';
export var ROUTE_DEAL_SUMMARY = 'ROUTE_DEAL_SUMMARY';
export var ROUTE_RESERVATION = 'ROUTE_RESERVATION';
export var ROUTE_RESERVATION_DEALER_INFO = 'ROUTE_RESERVATION_DEALER_INFO';
export var ROUTE_TEST_DRIVE = 'ROUTE_TEST_DRIVE';
export var ROUTE_VIEW_OFFER = 'ROUTE_VIEW_OFFER';
export var ROUTE_MESSAGING = 'ROUTE_MESSAGING';
export var ROUTE_TO_PROTECTION_LIST = 'ROUTE_TO_PROTECTION_LIST';
export var ROUTE_TO_PROTECTION_DETAIL = 'ROUTE_TO_PROTECTION_DETAIL';
