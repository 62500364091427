import { CashDealSummary, DealSummary, ServicesSummary } from './features/DealSummary';
import ActionCard from './features/ActionCard';
import ActivityStatusItemUI from './components/ActivityStatusItem/ActivityStatusItemUI';
import MenuProductCard from './features/MenuProductCard';
import ButtonCard from './features/ButtonCard';
import Header from './features/Header';
import MonthlyInstallment from './features/MonthlyInstallment';
import InfoIcon from './features/InfoIcon';
import Disclaimer from './features/Disclaimer';
import PrivacyPolicy from './features/PrivacyPolicy';
import VisitorAgreement from './features/VisitorAgreement';
import { LineItemUI, GroupLineItemUI } from './features/DealSummary/LineItemUI';
import * as FormatUtils from './utils/formatUtils';
import CurrencyInputUI from './components/CurrencyInput/CurrencyInputUI';
import TextInputUI from './components/TextInput/TextInputUI';
import TooltipUI from './components/Tooltip/TooltipUI';
// for type definitions:
import RadioButtonGroupUI from './components/RadioButtonGroup/RadioButtonGroupUI';
import LoadingButtonUI from './components/LoadingButton/LoadingButtonUI';
import HowItWorksUI from './features/HowItWorks/HowItWorksUI';
export * from './features/MenuProductCard/MenuProductImage';
// new way to import UIToolkit:
export var UIToolkit = {
    ActionCard: ActionCard,
    ActivityStatusItemUI: ActivityStatusItemUI,
    RadioButtonGroupUI: RadioButtonGroupUI,
    MenuProductCard: MenuProductCard,
    FormatUtils: FormatUtils,
    LineItemUI: LineItemUI,
    GroupLineItemUI: GroupLineItemUI,
    ButtonCard: ButtonCard,
    DealSummary: DealSummary,
    CashDealSummary: CashDealSummary,
    ServicesSummary: ServicesSummary,
    Header: Header,
    InfoIcon: InfoIcon,
    MonthlyInstallment: MonthlyInstallment,
    Disclaimer: Disclaimer,
    PrivacyPolicy: PrivacyPolicy,
    VisitorAgreement: VisitorAgreement,
    HowItWorksUI: HowItWorksUI,
    LoadingButtonUI: LoadingButtonUI,
    TooltipUI: TooltipUI,
    CurrencyInputUI: CurrencyInputUI,
    TextInputUI: TextInputUI
};
// support legacy use for compatibility
export default UIToolkit;
