var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Component } from 'react';
import * as PropTypes from 'prop-types';
var propTypes = {
    cardHeading: PropTypes.string.isRequired,
    handleBack: PropTypes.func,
    iconType: PropTypes.string
};
var HeaderUI = /** @class */ (function (_super) {
    __extends(HeaderUI, _super);
    function HeaderUI(props) {
        return _super.call(this, props) || this;
    }
    HeaderUI.prototype.handleBack = function () {
        this.props.handleBack(this.props.headerContext);
    };
    HeaderUI.prototype.handleHome = function () {
        this.props.handleHome();
    };
    HeaderUI.prototype.getCardHeading = function () {
        var _a = this.props, cardHeading = _a.cardHeading, headerContext = _a.headerContext;
        return headerContext && headerContext.title || cardHeading;
    };
    HeaderUI.prototype.render = function () {
        var _a = this.props, cardHeading = _a.cardHeading, iconType = _a.iconType, handleBack = _a.handleBack;
        var leftIcon = React.createElement("a", { className: "dr-ui-btn-back", onClick: this.handleBack.bind(this) },
            React.createElement("i", { className: "fa fa-angle-left fa-2x", "aria-hidden": "true" }),
            "\u00A0");
        var rightIcon = React.createElement("a", { className: "dr-ui-btn-home", onClick: this.handleHome.bind(this) },
            React.createElement("i", { className: "fa fa-home fa-2x", "aria-hidden": "true" }),
            "\u00A0");
        return (React.createElement("div", { className: "dr-ui-header" },
            React.createElement("div", { className: "nav" },
                rightIcon,
                leftIcon,
                React.createElement("div", { className: "dr-ui-center-outer" },
                    React.createElement("div", { className: "header" }, this.getCardHeading())))));
    };
    HeaderUI.defaultProps = {
        cardHeading: 'Card Heading',
        iconType: 'default'
    };
    return HeaderUI;
}(Component));
export default HeaderUI;
