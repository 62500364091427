import { assign } from 'lodash';
import * as types from './actions/actionTypes';
export default function headerReducer(state, action) {
    if (state === void 0) { state = { title: '' }; }
    var newState = assign({}, state);
    switch (action.type) {
        case types.ROUTE_MESSAGING: {
            return assign({}, newState, { title: 'Message with the Dealer' });
        }
        case types.ROUTE_CREDIT_RESULTS:
        case types.ROUTE_CREDIT_APP: {
            return assign({}, newState, { title: 'Apply for Credit' });
        }
        case types.ROUTE_RESERVATION_DEALER_INFO:
        case types.ROUTE_RESERVATION: {
            return assign({}, newState, { title: 'Reserve this Vehicle' });
        }
        case types.ROUTE_TEST_DRIVE: {
            return assign({}, newState, { title: 'Schedule Test Drive' });
        }
        case types.ROUTE_TO_PROTECTION_LIST: {
            return assign({}, newState, { title: 'Explore Vehicle Protection' });
        }
        case types.ROUTE_VIEW_OFFER:
        case types.ROUTE_DEAL_SUMMARY: {
            return assign({}, newState, { title: 'My Offer' });
        }
        case types.ROUTE_TO_PROTECTION_DETAIL: {
            return assign({}, newState, { title: 'Add Protection' });
        }
        default: {
            return state;
        }
    }
}
