import * as React from 'react';
import { GroupLineItemUI, LineItemUI } from './LineItemUI';
var SummaryUI = function (props) {
    var advertisedPriceLabel = props.advertisedPriceLabel, amountFinanced = props.amountFinanced, annualMiles = props.annualMiles, apr = props.apr, aprNotAvailableText = props.aprNotAvailableText, children = props.children, downPayment = props.downPayment, downPaymentText = props.downPaymentText, dueAtSigningNegativeTradeIn = props.dueAtSigningNegativeTradeIn, dueAtSigningText = props.dueAtSigningText, financeDownPayment = props.financeDownPayment, financeNegativeTradeIn = props.financeNegativeTradeIn, listedPrice = props.listedPrice, notAvailableText = props.notAvailableText, offerPrice = props.offerPrice, positiveTradeIn = props.positiveTradeIn, retailPrice = props.retailPrice, specials = props.specials, specialsLabel = props.specialsLabel, termMonths = props.termMonths, termNotAvailableText = props.termNotAvailableText, totalDueAtSigning = props.totalDueAtSigning, vehicleType = props.vehicleType;
    return (React.createElement("div", { className: "dr-ui-summary-container" },
        React.createElement("div", { className: "first-column" },
            React.createElement(LineItemUI, { label: "MSRP", value: retailPrice, id: "msrp" }),
            React.createElement(LineItemUI, { label: advertisedPriceLabel, value: listedPrice, id: "advertisedPrice" }),
            React.createElement("hr", null),
            React.createElement(LineItemUI, { label: "My Price", value: offerPrice, id: "offerPrice" }),
            vehicleType !== 'new' &&
                React.createElement(LineItemUI, { label: specialsLabel, value: specials, notAvailableText: notAvailableText, id: "specials" }),
            React.createElement(LineItemUI, { label: "Trade-In Equity", value: positiveTradeIn, id: "tradeAllowance" }),
            React.createElement(LineItemUI, { label: "Trade-In Equity", value: financeNegativeTradeIn, id: "tradeAllowanceNegative" }),
            React.createElement(LineItemUI, { label: downPaymentText, value: financeDownPayment, id: "downPaymentNegative" }),
            React.createElement(LineItemUI, { label: "Taxes and Fees", value: "Not Included", id: "taxesAndFees" }),
            React.createElement(GroupLineItemUI, { label: "Total Financed", value: amountFinanced, notAvailableText: notAvailableText, id: "amountFinanced" }),
            React.createElement("hr", null)),
        React.createElement("div", { className: "second-column" },
            React.createElement(LineItemUI, { label: downPaymentText, value: downPayment, id: "downPayment" }),
            React.createElement(LineItemUI, { label: "Trade-In Equity", value: dueAtSigningNegativeTradeIn, id: "tradeAllowance" }),
            React.createElement(GroupLineItemUI, { label: dueAtSigningText, value: totalDueAtSigning, id: "dueAtSigning" }),
            React.createElement(GroupLineItemUI, { label: "Term", value: termMonths, notAvailableText: termNotAvailableText, id: "term" }),
            React.createElement(GroupLineItemUI, { label: "APR", value: apr, notAvailableText: aprNotAvailableText, id: "apr" }),
            React.createElement(GroupLineItemUI, { label: "Annual Miles", value: String(annualMiles), id: "annualMiles" }),
            children)));
};
export default SummaryUI;
