import * as React from 'react';
/* tslint:disable:max-line-length */
export var StandardPrivacyPolicyContentUI = function (props) {
    return (React.createElement("div", null,
        React.createElement("div", { className: "span12" },
            React.createElement("h1", { id: "top", className: "dr-ui-legal-docs-headline" }, "Privacy Statement "),
            React.createElement("p", null,
                props.dealerName,
                " uses an experience on this website that is provided by one of our technology partners who provides the display of such items that may contain Payments, Credit Terms, Trade-In Values, and other tools that help provide a better shopping experience.  The use of this experience and the Privacy Policy is governed by their privacy policy below:"),
            React.createElement("hr", null),
            React.createElement("h3", { id: "top" }, "Autotrader Privacy Statement"),
            React.createElement("p", null, "Effective Date: March 16, 2015"),
            React.createElement("h4", null, "Introduction"),
            React.createElement("p", null, "Thank you for your interest in Autotrader. We respect the privacy of our users when they use our websites, mobile and other online applications and products and services (collectively, the \"Autotrader Sites\"). By using Autotrader Sites, you consent to the use of your information and information about you in accordance with this Privacy Statement."),
            React.createElement("p", null, "When we refer to Autotrader within this Privacy Statement, we are referring to Autotrader, Inc. This Privacy Statement applies to information collected through the Autotrader Sites and does not describe the collection, use and disclosure practices of any of our corporate affiliates or third parties. For information on the collection, use and disclosure practices of any company with whom you are doing business, whether or not affiliated with Autotrader, please review their privacy policies."),
            React.createElement("p", null, "Services offered by Autotrader are not directed to individuals under the age of eighteen (18). If you are under eighteen (18), you should not provide any personal information to us."),
            React.createElement("h5", null, "This Privacy Statement covers: "),
            React.createElement("ul", null,
                React.createElement("li", null,
                    React.createElement("a", { href: "#1" }, "Information We Collect"),
                    React.createElement("ul", null,
                        React.createElement("li", null,
                            React.createElement("a", { href: "#2" }, "Information You Provide")),
                        React.createElement("li", null,
                            React.createElement("a", { href: "#3" }, "Automated Information")),
                        React.createElement("li", null,
                            React.createElement("a", { href: "#4" }, "Data Technologies")),
                        React.createElement("li", null,
                            React.createElement("a", { href: "#5" }, "Videos")),
                        React.createElement("li", null,
                            React.createElement("a", { href: "#6" }, "Location Information")),
                        React.createElement("li", null,
                            React.createElement("a", { href: "#7" }, "Publicly Posted Information")),
                        React.createElement("li", null,
                            React.createElement("a", { href: "#8" }, "Combined Information and Information from Other Sources")))),
                React.createElement("li", null,
                    React.createElement("a", { href: "#9" }, "How We May Use the Information We Collect")),
                React.createElement("li", null,
                    React.createElement("a", { href: "#10" }, "Applications, Widgets and Social Media")),
                React.createElement("li", null,
                    React.createElement("a", { href: "#11" }, "Advertising Networks")),
                React.createElement("li", null,
                    React.createElement("a", { href: "#12" }, "Information We Share"),
                    React.createElement("ul", null,
                        React.createElement("li", null,
                            React.createElement("a", { href: "#13" }, "Affiliates")),
                        React.createElement("li", null,
                            React.createElement("a", { href: "#14" }, "Our Service Providers")),
                        React.createElement("li", null,
                            React.createElement("a", { href: "#15" }, "Co-Sponsored Promotions")),
                        React.createElement("li", null,
                            React.createElement("a", { href: "#16" }, "Promotions on Behalf of Third Parties")),
                        React.createElement("li", null,
                            React.createElement("a", { href: "#17" }, "Sellers, Dealers and Manufacturers")),
                        React.createElement("li", null,
                            React.createElement("a", { href: "#18" }, "Credit Applications")),
                        React.createElement("li", null,
                            React.createElement("a", { href: "#19" }, "Corporate and Asset Transactions")),
                        React.createElement("li", null,
                            React.createElement("a", { href: "#20" }, "Other")))),
                React.createElement("li", null,
                    React.createElement("a", { href: "#21" }, "Your Choices")),
                React.createElement("li", null,
                    React.createElement("a", { href: "#22" }, "Your California Privacy Rights")),
                React.createElement("li", null,
                    React.createElement("a", { href: "#23" }, "Access and Correction")),
                React.createElement("li", null,
                    React.createElement("a", { href: "#24" }, "Links")),
                React.createElement("li", null,
                    React.createElement("a", { href: "#25" }, "Data Security")),
                React.createElement("li", null,
                    React.createElement("a", { href: "#26" }, "Disputes")),
                React.createElement("li", null,
                    React.createElement("a", { href: "#27" }, "Updates to this Privacy Statement")),
                React.createElement("li", null,
                    React.createElement("a", { href: "https://www.autotrader.com/legal/privacy-statement.xhtml#contact" }, "How to Contact Us"))),
            React.createElement("hr", null),
            React.createElement("h4", { id: "1" }, "Information We Collect "),
            React.createElement("p", null, "Information is gathered from the Autotrader Sites by us, our service providers, advertisers, sponsors and partners."),
            React.createElement("p", { id: "2" }, "Information You Provide. We may collect information that you choose to provide on the Autotrader Sites in a variety of ways, such as when you:"),
            React.createElement("ol", null,
                React.createElement("li", null, "register for one of our products or services. This information may include your name, address, telephone number, email address, Social Security Number, and credit information;"),
                React.createElement("li", null, "make information available to us via social networking logins, such as Facebook or Twitter;"),
                React.createElement("li", null, "express an interest in buying, selling or trading in a car (such as by using our \"email the seller\" function);"),
                React.createElement("li", null, "participate in chats, web forums or other interactive sessions;"),
                React.createElement("li", null, "apply for credit with a dealer or one of our finance partners;"),
                React.createElement("li", null, "provide payment card information for one of our products or services;"),
                React.createElement("li", null, "subscribe to newsletters or provide feedback; and"),
                React.createElement("li", null, "participate in surveys, sweepstakes or other promotions.")),
            React.createElement("p", { id: "3" }, "Automated Information. Information regarding your use of the Autotrader Sites may be collected and/or aggregated through the use of automated means. This automatically collected information may include information such as:"),
            React.createElement("ol", null,
                React.createElement("li", null, "your IP address, the type of browser and operating system used;"),
                React.createElement("li", null, "date and time you access the Autotrader Site and pages you visit, and if you linked from or to another website, the address of that website;"),
                React.createElement("li", null, "email you open and links you click on within those email; and"),
                React.createElement("li", null, "your ISP or mobile carrier, and the type of handheld or mobile device that you used.")),
            React.createElement("p", { id: "4" }, "Data Technologies. Various technologies are used to automatically collect information, such as cookies, local shared objects, and web beacons (generally referred to as \"Data Technologies\". \"Cookies\" are small identifiers sent from a web server that are stored on your device for the purpose of identifying your browser or storing information or settings in your browser. \"Local shared objects,\" sometimes known as Flash cookies, may be used to store your preferences or display content based upon what you have viewed on various websites to personalize your visit. A \"web beacon,\" also known as an Internet tag, pixel tag or clear GIF, links web pages to web servers and their cookies. Anonymous advertising identifiers are increasingly being used on mobile devices and otherwise in a manner similar to cookies. When we refer to Data Technologies, we are including all current and similar future technologies."),
            React.createElement("p", null, "One or more Data Technologies may be used on the Autotrader Sites and other websites and mobile applications (that may not be the Autotrader Sites) by us, by another party on our behalf, or by third parties in accordance with their privacy policies. Data Technologies may be used by us and others, on our behalf and on their own behalf, to transmit information to you or about you and connect information about you from different sources, websites, devices, and mobile applications."),
            React.createElement("p", null, "We may use a uniquely generated trackable toll-free telephone number on dealer sites or in advertisements. If you call one of these uniquely generated toll-free numbers, we (or a service provider acting on our behalf) may collect non-personally identifiable information about the call (e.g. date, time, duration), as well as personally identifiable information (e.g. your name, the number from which your call originated)."),
            React.createElement("p", { id: "5" }, "Videos. We may target and track the videos you view on the Sites. You consent to our tracking of your video viewing through the Site or third party social media for up to two years or as permitted by applicable law."),
            React.createElement("p", { id: "6" }, "Location Information. When you download or use ths Autotrader Sites, we may collect physical location information that is sufficiently precise to allow us to locate a specific person or device (\"Location Information\"). We also may collect Location Information when you visit or are in the vicinity of one of our dealers. We only collect Location Information with your permission (e.g., such as when you agree to provide it to us after you initially access or download the Autotrader Sites). We may use Location Information and provide it to third parties to provide you with content and services that are relevant to a particular location, such as advertising, search results, dealers in the area, and direction, to evaluate and improve the Autotrader Sites, or to provide you with content and services relevant to one or more nearby dealers."),
            React.createElement("p", { id: "7" }, "Publicly Posted Information. Information that you make available to us and others via social media networks, forums, blogs, list serves, chat rooms or similar functionality is public information that we or others may share or use in accordance with the law."),
            React.createElement("p", { id: "8" }, "Combined Information and Information from Other Sources. We may combine information that you provide to us with information we receive from our affiliates and other sources, as well as with other information that is automatically collected. The combined information may include information about your use of the Sites, your use of other websites, devices and mobile applications and information from our affiliates and other sources."),
            React.createElement("a", { href: "#top" },
                React.createElement("i", { className: "icon-circle-arrow-up" }),
                "\u00A0Back to top"),
            React.createElement("hr", null),
            React.createElement("h4", { id: "9" }, "How We May Use the Information We Collect"),
            React.createElement("p", null, "We may use information we collect for the following purposes:"),
            React.createElement("ol", null,
                React.createElement("li", null, "for everyday business purposes, such as establishing and managing your user account, providing products and services you request, processing and collecting payments and providing customer support;"),
                React.createElement("li", null, "to create listings and advertisements for any cars and other vehicles that you list for sale with us. These lists and advertisements may appear on the Autotrader Sites and other websites, mobile applications or services of third parties;"),
                React.createElement("li", null, "to provide values and/or offers for cars that you would like to sell or trade in;"),
                React.createElement("li", null, "to offer our other products or services and/or the products or services of others to you;"),
                React.createElement("li", null, "to communicate about, and administer your participation in, special events, programs, surveys, contests, sweepstakes and other offers or promotions;"),
                React.createElement("li", null, "to enable you to interact with Autotrader and our users, dealers, manufacturers and others through various means, such as through our message boards, blogs and social media;"),
                React.createElement("li", null, "to evaluate and improve our business, including developing new products and services and analyzing the effectiveness of products, services, applications and websites;"),
                React.createElement("li", null, "to diagnose and address technical and service problems;"),
                React.createElement("li", null, "to perform data analyses, including market and consumer research, trend analysis, demographic analysis and financial analysis;"),
                React.createElement("li", null, "to deliver content (including advertising) correlating to your interests and browsing and usage history, both within the Autotrader Sites and on other websites and applications;"),
                React.createElement("li", null, "to comply with applicable legal requirements and our policies; and"),
                React.createElement("li", null, "to contact you via telephone, text or chat. We will obtain your consent to contact you when and in the manner required by law.")),
            React.createElement("a", { href: "#top" },
                React.createElement("i", { className: "icon-circle-arrow-up" }),
                "\u00A0Back to top"),
            React.createElement("hr", null),
            React.createElement("h4", { id: "10" }, "Applications, Widgets and Social Media"),
            React.createElement("p", null, "We may include applications or widgets from social media providers that allow interaction or content sharing by their users. These widgets, such as a Facebook \"Share\" or \"Like\" button, are visible to you on the web page you visit. Integration between the Autotrader Sites and social media networks such as Facebook, Twitter and others may allow social media networks in which you participate to collect information about you, even when you do not explicitly activate the network's application or widget. Please visit the applicable social media network's privacy policy to better understand their data collection practices and choices they make available to you. The privacy policy of the social media network controls the collection, use and disclosure of all personal information transmitted to that network."),
            React.createElement("a", { href: "#top" },
                React.createElement("i", { className: "icon-circle-arrow-up" }),
                "\u00A0Back to top"),
            React.createElement("hr", null),
            React.createElement("h4", { id: "11" }, "Advertising Networks"),
            React.createElement("p", null, "Advertising that is customized based on predictions generated over time from your visits across different websites, devices and mobile applications is sometimes called \"online behavioral\" or \"interest-based\" advertising. We may partner with advertising networks, which are companies that deliver targeted advertisements to consumers and generate statistics and metrics related to the delivery of interest-based advertisements. They may utilize information collected through a variety of Data Technologies to provide customization, auditing, research and reporting for us, our affiliates and other advertisers. This data collection takes place both on the Autotrader Sites and on third-party's websites and mobile applications. This process allows Autotrader and third parties to deliver targeted advertising, enhance marketing programs and help track the effectiveness of such efforts. Our advertising networks also may use this information for determining or predicting the characteristics and preferences of their respective advertising audiences and measuring the effectiveness of their advertising in accordance with their privacy policies."),
            React.createElement("p", null, "We do not provide information that is directly associated with a specific person (such as name and address) to an advertising network when you interact with or view a customized advertisement. However, when you view or interact with an advertisement, the advertiser may make an assumption that you are interested in the subject matter of the advertisement."),
            React.createElement("p", null,
                "Some companies disclose when they are using interest-based advertising programs on the Autotrader Sites to deliver third-party ads or collecting information about your visit for these purposes and give you the ability to opt-out of this use of your information. You may see an icon",
                React.createElement("a", { href: "http://www.aboutads.info/", target: "_blank", rel: "noopener noreferrer" },
                    React.createElement("img", { width: "22", height: "22", id: "Picture 1", src: "https://www.makemydeal.com/content/img/aboutads.jpg", alt: "about ads" })),
                "in or around third-party advertisements on the Autotrader Sites that use interest-based advertising programs and on pages where data is collected and used for online interest-based advertising. Clicking on this icon will provide additional information about the companies and data practices that were used to deliver the ad. You can opt out of delivery of targeted advertising to you by multiple companies by visiting",
                React.createElement("a", { href: "http://www.aboutads.info/choices", target: "_blank", rel: "noopener noreferrer" }, " www.aboutads.info/choices"),
                ",",
                React.createElement("a", { href: "http://www.networkadvertising.org/managing/opt_out.asp", target: "_blank", rel: "noopener noreferrer" }, " www.networkadvertising.org/managing/opt_out.asp "),
                "and",
                React.createElement("a", { href: "http://www.evidon.com", target: "_blank", rel: "noopener noreferrer" }, " www.evidon.com"),
                ".  Please note that even if you opt out, you will continue to receive advertisements, but they will not be tailored to your specific interests."),
            React.createElement("a", { href: "#top" },
                React.createElement("i", { className: "icon-circle-arrow-up" }),
                "\u00A0Back to top"),
            React.createElement("hr", null),
            React.createElement("h4", { id: "12" }, "Information We Share"),
            React.createElement("p", null, "We do not sell or otherwise share information about you that we collect or receive, except as described below:"),
            React.createElement("ol", null,
                React.createElement("li", { id: "13" },
                    "Affiliates. We may share information about you, including personal information, with our corporate affiliates, who will use it in accordance with their privacy policies. Please visit",
                    React.createElement("a", { href: "http://www.coxautoinc.com/" }, " http://www.coxautoinc.com"),
                    " for more information about our affiliates."),
                React.createElement("li", { id: "14" }, "Our Service Providers. We may engage third parties to provide the services offered through or in connection with the Autotrader Sites on our behalf. We require such providers to maintain information about you as confidential and to use the information only to perform the services specified by us in a manner consistent with this Privacy Statement."),
                React.createElement("li", { id: "15" }, "Co-Sponsored Promotions. In the event that you participate in a survey, sweepstakes, contest or other promotion that is co-sponsored by Autotrader and a third party, we may share information collected in conjunction with the survey, sweepstakes, contest or promotion, including personal information, with that third party. After the information is provided to those third parties, the subsequent use or disclosure of such information is subject to their privacy policies and practices. Surveys, sweepstakes and other promotions conducted on behalf of Autotrader and a third party will include branding from both Autotrader and the third party."),
                React.createElement("li", { id: "16" }, "Promotions on behalf of Third Parties. Periodically, third parties may conduct surveys, contests, sweepstakes and other promotions for their own purpose on the Autotrader Sites. Information you provide in response to those activities is governed by their privacy policies. Surveys, sweepstakes and other promotions conducted solely for the purpose of a third party will include only the third party's branding."),
                React.createElement("li", { id: "17" }, "Sellers, Dealers and Manufacturers. Information about you, including personal information and Data Technologies, may be shared with sellers, dealers and manufacturers, and their agents and third party service providers, who will use that information in accordance with their own privacy policies. For example:"),
                React.createElement("ol", null,
                    React.createElement("li", null, "If you are interested in a particular listing of a car for sale, information about you may be shared with the seller of that particular car or similar cars or dealers in your area;"),
                    React.createElement("li", null, "If you are considering trading in a car, we may provide information about you to dealers who participate in our trade-in program and other dealers in your area;"),
                    React.createElement("li", null, "We may provide manufacturers, their agents or third party service providers, with information about you that we provided to their franchised dealers;"),
                    React.createElement("li", null, "We may provide manufacturers, their agents or third party service providers, with information about you if you are interested in certified pre-owned vehicles; or"),
                    React.createElement("li", null, "We may provide manufacturers, their agents or third party service providers, and dealers with information about you when you access the Autotrader Sites via Autotrader branded advertising and/or functionality found on their websites or other third party websites.")),
                React.createElement("li", { id: "18" }, "Credit Applications. If you apply for credit on the Autotrader Sites with us, our dealers, or any third party, it will be necessary for you to provide personal information to complete the application, including your address, credit information and history, employment and other financial information. We may share your application information with the dealership(s) you selected and our financial partners so that they can process your application and otherwise use it in accordance with their privacy policies, however, we will not use your personal financial information for interest based advertising."),
                React.createElement("li", { id: "19" }, "Corporate and Asset Transactions. If we sell all or substantially all of our business or sell or transfer all or a material part of our assets, or are otherwise involved in a merger or transfer of all or a substantial part of our business, we may transfer all information we have collected and stored, including personal information, to the party or parties involved in the transaction as part of that transaction."),
                React.createElement("li", { id: "20" },
                    "Other. We may access or disclose information, including personal information, to:",
                    React.createElement("ol", null,
                        React.createElement("li", null, "protect or defend our interests and the legal rights or property of Autotrader and our affiliates;"),
                        React.createElement("li", null, "protect the rights, interests, safety and security of users of the Autotrader Sites or members of the public;"),
                        React.createElement("li", null, "protect against fraud or for risk management purposes; or"),
                        React.createElement("li", null, "comply with prudent legal practice as we may determine, and applicable law or legal process.")))),
            React.createElement("a", { href: "#top" },
                React.createElement("i", { className: "icon-circle-arrow-up" }),
                "\u00A0Back to top"),
            React.createElement("hr", null),
            React.createElement("h4", { id: "21" }, "Your Choices"),
            React.createElement("p", null, "You are entitled to make certain choices about how we communicate with you."),
            React.createElement("ol", null,
                React.createElement("li", null, "You may choose not to provide personal information, even though that might impact your ability to register or receive a particular product or service."),
                React.createElement("li", null, "If you do not want to receive marketing email from us, you can follow the unsubscribe link provided in those emails."),
                React.createElement("li", null, "If you do not want to receive interest-based advertisements, you can opt out as discussed in the \"Advertising Networks\" section above."),
                React.createElement("li", null, "You have a number of choices regarding certain Data Technologies. Most web browsers automatically accept cookies, but you can usually modify your browser's setting to decline cookies if you prefer. You may also render some web beacons unusable by rejecting their associated cookies and disable or delete similar data used by browser add-ons, such as Flash cookies, by changing the add-on's settings or opting out at Evidon.com or the website of the technology provider. If you choose to decline cookies or similar technologies, however, please note that certain features may not function properly or at all as a result. You may be able to adjust the use of advertising identifiers on mobile devices through the settings on your device. Finally, if you do not want to use a uniquely generated telephone number, you may choose to not call us."),
                React.createElement("li", null, "If you do not want us to use Location Information, you can decline our initial invitation to provide that information, or opt out by changing the location services settings on your mobile device. However, please note that certain features may not function properly or at all as a result if you opt out.")),
            React.createElement("a", { href: "#top" },
                React.createElement("i", { className: "icon-circle-arrow-up" }),
                "\u00A0Back to top"),
            React.createElement("hr", null),
            React.createElement("h4", { id: "22" }, "Your California Privacy Rights"),
            React.createElement("p", null, "California Civil Code Section 1798.83 entitles California users to request information concerning whether a business has disclosed certain information about you to any third parties for the third parties' direct marketing purposes. California users who wish to request further information in compliance with this law or have questions or concerns about our privacy practices and policies may contact us as specified in the \"How to Contact Us\" section below."),
            React.createElement("p", null, "Online Tracking Policy for California Residents: As of the effective date of this Privacy Statement, there is no commonly accepted response for Do Not Track signals initiated by browsers, therefore we do not respond to them."),
            React.createElement("a", { href: "#top" },
                React.createElement("i", { className: "icon-circle-arrow-up" }),
                "\u00A0Back to top"),
            React.createElement("hr", null),
            React.createElement("h4", { id: "23" }, "Access and Correction"),
            React.createElement("p", null, "Access to information that we collect and maintain about you may be available to you. For example, if you created a password-protected account within the Autotrader Sites, you can access that account to update the information you previously provided. Additionally, if you believe that information we maintain about you is inaccurate, subject to applicable law, you may request that we correct or amend the information by contacting us as indicated in the \"How to Contact Us\" section below."),
            React.createElement("a", { href: "#top" },
                React.createElement("i", { className: "icon-circle-arrow-up" }),
                "\u00A0Back to top"),
            React.createElement("hr", null),
            React.createElement("h4", { id: "24" }, "Links"),
            React.createElement("p", null, "The Autotrader Sites may contain links to websites not operated by us, as well as third party widgets and applications discussed above. Once you leave the Autotrader Sites, we suggest that you review the applicable privacy policy of the third-party website or application. We are not responsible for the content or use of any websites or applications other than on the Autotrader Sites or the privacy practices of those websites or applications."),
            React.createElement("a", { href: "#top" },
                React.createElement("i", { className: "icon-circle-arrow-up" }),
                "\u00A0Back to top"),
            React.createElement("hr", null),
            React.createElement("h4", { id: "25" }, "Data Security"),
            React.createElement("p", null, "We use commercially reasonable administrative, technical, personnel, and physical security measures designed to safeguard information about you in our possession against loss, theft and unauthorized use, disclosure or modification. Of course, despite these measures, we cannot guarantee perfect security of networks, servers and databases we operate or that are operated on our behalf."),
            React.createElement("a", { href: "#top" },
                React.createElement("i", { className: "icon-circle-arrow-up" }),
                "\u00A0Back to top"),
            React.createElement("hr", null),
            React.createElement("h4", { id: "26" }, "Disputes"),
            React.createElement("p", null, "If you choose to visit the Autotrader Sites, your visit and any dispute regarding privacy is subject to this Privacy Statement and our Visitor Agreement, including limitations on damages, resolution of disputes, and application of the law of the state of Georgia."),
            React.createElement("a", { href: "#top" },
                React.createElement("i", { className: "icon-circle-arrow-up" }),
                "\u00A0Back to top"),
            React.createElement("hr", null),
            React.createElement("h4", { id: "27" }, "Updates to this Privacy Statement"),
            React.createElement("p", null, "We may update this Privacy Statement to provide clarification or notice of changes to our practices. If we make changes, we will revise the date at the top of this Privacy Statement. We will provide you with additional notice of material changes by posting notice of the changes for thirty (30) days before we implement those changes."),
            React.createElement("a", { href: "#top" },
                React.createElement("i", { className: "icon-circle-arrow-up" }),
                "\u00A0Back to top"),
            React.createElement("hr", null),
            React.createElement("h4", { id: "28" }, "How to Contact Us"),
            React.createElement("p", null, "If you have any questions or comments about this Privacy Statement, or if you would like us to update information we have about you or your preferences, please contact us by email at privacy@autotrader.com."),
            React.createElement("a", { href: "#top" },
                React.createElement("i", { className: "icon-circle-arrow-up" }),
                "\u00A0Back to top"))));
};
