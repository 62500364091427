var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Component } from 'react';
import { debounce } from 'lodash';
import { formatDollars, isValidNumber } from '../../utils/formatUtils';
import ToolTipIconUI from '../ToolTipIcon/ToolTipIconUI';
var CurrencyInputUI = /** @class */ (function (_super) {
    __extends(CurrencyInputUI, _super);
    function CurrencyInputUI(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            currencyValue: props.currencyValue,
            displayError: false
        };
        _this.debouncedUpdateValue = debounce(function () { _this.props.onUpdateValue(_this.state.currencyValue); }, 2000);
        return _this;
    }
    CurrencyInputUI.prototype.componentWillReceiveProps = function (nextProps) {
        if (nextProps.currencyValue !== this.state.currencyValue) {
            this.setState({ currencyValue: nextProps.currencyValue });
        }
    };
    CurrencyInputUI.prototype.immediateUpdateValue = function (value) {
        if (this.props.onImmediateUpdateValue) {
            this.props.onImmediateUpdateValue(value);
        }
    };
    CurrencyInputUI.prototype.onChange = function (event) {
        this.setState({ displayError: false });
        var value = event.target.value;
        value = value.replace('$', '').replace(/,/g, '');
        if (isValidNumber(value)) {
            value = Number(value);
            this.setState({ currencyValue: value });
            this.immediateUpdateValue(value); // to update state tree immediately
            this.debouncedUpdateValue(); // to defer API call until we think user has finished typing
        }
    };
    CurrencyInputUI.prototype.onBlur = function (event) {
        var isValidValue = true;
        if (this.props.onBlurValidation) {
            isValidValue = this.props.onBlurValidation(this.state.currencyValue);
        }
        if (isValidValue) {
            this.setState({ displayError: false });
        }
        else {
            this.setState({ displayError: true });
        }
        if (this.props.onBlur) {
            this.props.onBlur(event.target.value);
        }
    };
    CurrencyInputUI.prototype.onFocus = function (event) {
        if (this.props.forceCursorToEnd) {
            var length_1 = event.target.value.length;
            var inputTarget_1 = event.target;
            if (length_1 > 0) {
                setTimeout(function () {
                    inputTarget_1.setSelectionRange(length_1, length_1);
                }, 0);
            }
        }
    };
    CurrencyInputUI.prototype.render = function () {
        var _this = this;
        var _a = this.props, label = _a.label, placeHolder = _a.placeHolder, onAlertClick = _a.onAlertClick, id = _a.id, analyticsId = _a.analyticsId, showAlert = _a.showAlert, isReadOnly = _a.isReadOnly;
        var labelDiv = (React.createElement("div", { className: "currency-input__label control-label inline-label input-label" }, label));
        var placeholderText = placeHolder;
        if (this.state.displayError) {
            placeholderText = placeHolder ? placeholderText : 'Enter a valid value';
        }
        var alertComponent = (React.createElement("div", { className: "info-icon" },
            React.createElement(ToolTipIconUI, { iconClass: "info-circle", onClick: onAlertClick })));
        var inputClasses = 'form-control';
        inputClasses = showAlert ? inputClasses + " show-alert" : inputClasses;
        inputClasses = isReadOnly ? inputClasses + " currency-input--read-only" : inputClasses;
        var dollarValue = formatDollars(this.state.currencyValue);
        return (React.createElement("div", { className: "currency-input" },
            React.createElement("div", { className: "input-group" },
                label ? labelDiv : null,
                React.createElement("input", { className: inputClasses, id: id, pattern: "[0-9]*", "data-analytics": analyticsId, type: "tel", inputMode: "numeric", placeholder: placeHolder, value: dollarValue, onChange: function (event) { _this.onChange(event); }, onBlur: function (event) { _this.onBlur(event); } }),
                showAlert ? alertComponent : null)));
    };
    return CurrencyInputUI;
}(Component));
export default CurrencyInputUI;
