import { connect } from 'react-redux';
import MonthlyInstallmentUI from './MonthlyInstallmentUI';
var mapStateToProps = function (state) {
    return {};
};
var mapDispatchToProps = function (dispatch) {
    return {};
};
var MonthlyInstallment = connect(mapStateToProps, mapDispatchToProps)(MonthlyInstallmentUI);
export default MonthlyInstallment;
