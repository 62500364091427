var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Component } from 'react';
import * as PropTypes from 'prop-types';
import { formatCurrencyAmount } from '../../utils/formatUtils';
var PriceLabelUI = /** @class */ (function (_super) {
    __extends(PriceLabelUI, _super);
    function PriceLabelUI(props) {
        return _super.call(this, props) || this;
    }
    PriceLabelUI.prototype.componentDidMount = function () {
    };
    PriceLabelUI.prototype.render = function () {
        var _a = this.props, price = _a.price, className = _a.className, dealerPhone = _a.dealerPhone, hasError = _a.hasError, isCashOffer = _a.isCashOffer, vppMonthlyPaymentLabeled = _a.vppMonthlyPaymentLabeled, offerType = _a.offerType, isMenuPaymentsDisclaimerEnabled = _a.isMenuPaymentsDisclaimerEnabled, isVppMonthlyPaymentEnabled = _a.isVppMonthlyPaymentEnabled;
        var classNames = "dr-ui-price-label " + className;
        var priceToDisplay = !price ? null : formatCurrencyAmount(price);
        if (hasError) {
            return (React.createElement("div", { className: classNames },
                React.createElement("div", null, "Call for Pricing"),
                React.createElement("div", { className: "phone-number" },
                    React.createElement("a", { href: "tel:" + dealerPhone },
                        React.createElement("i", { className: "dr-ui-icon fa fa-phone" })),
                    React.createElement("a", { href: "tel:" + dealerPhone }, dealerPhone))));
        }
        var priceDiscloser = function (price) { return (React.createElement("p", null,
            "Total price ",
            price,
            " plus",
            React.createElement("br", null),
            " taxes and interest ")); };
        return (React.createElement("div", { className: classNames },
            isVppMonthlyPaymentEnabled && !isCashOffer && (React.createElement("div", null,
                React.createElement("div", { className: "est-payment" },
                    "Est. Payment",
                    this.props.isMenuPaymentsDisclaimerEnabled
                        ? (React.createElement("span", { className: "disclaimer" },
                            "based on ",
                            this.props.offerType === 'finance' ? 'loan' : 'lease',
                            " term"))
                        : null),
                React.createElement("div", { className: "dr-ui-price-label-total" }, vppMonthlyPaymentLabeled),
                React.createElement("div", { className: "dr-ui-price-total-desc" }, price ? priceDiscloser(priceToDisplay) : ''))),
            !isVppMonthlyPaymentEnabled || (isVppMonthlyPaymentEnabled && isCashOffer) && (React.createElement("div", null,
                React.createElement("div", { className: "dr-ui-price-label-header" }, "Price"),
                React.createElement("div", { className: "dr-ui-price-label-total" }, priceToDisplay),
                React.createElement("div", null, this.props.price ? 'plus taxes and interest' : '')))));
    };
    PriceLabelUI.propTypes = {
        className: PropTypes.string,
        price: PropTypes.number,
        hasError: PropTypes.bool,
        dealerPhone: PropTypes.string,
        offerType: PropTypes.string,
        isMenuPaymentsDisclaimerEnabled: PropTypes.bool
    };
    return PriceLabelUI;
}(Component));
export { PriceLabelUI };
