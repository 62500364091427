var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Component } from 'react';
import { mileageToString, UNLIMITED_MILEAGE } from '@makemydeal/dr-platform-shared';
import RadioButtonGroupUI from '../../components/RadioButtonGroup/RadioButtonGroupUI';
var RadioButtonGroup = RadioButtonGroupUI;
import ProductPackageCardGroupUI from '../../components/ProductPackageCardGroup/ProductPackageCardGroupUI';
var ProductPackageCardGroup = ProductPackageCardGroupUI;
import { Parser } from 'bbcode-to-react';
var parser = new Parser();
var MenuProductDetails = /** @class */ (function (_super) {
    __extends(MenuProductDetails, _super);
    function MenuProductDetails() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MenuProductDetails.prototype.handleTermChange = function (termValue) {
        if (this.props.onTermChange) {
            this.props.onTermChange(this.stringToTerm(termValue));
        }
    };
    MenuProductDetails.prototype.handleMileageChange = function (mileageValue) {
        if (this.props.onMileageChange) {
            this.props.onMileageChange(this.stringToMileage(mileageValue));
        }
    };
    MenuProductDetails.prototype.mileageToStringWithProps = function (mileage) {
        var mileageToStringResult = mileageToString(mileage);
        if (mileageToStringResult === UNLIMITED_MILEAGE) {
            this.props.mapUnlimitedToMiles[UNLIMITED_MILEAGE] = mileage;
        }
        return mileageToStringResult;
    };
    MenuProductDetails.prototype.stringToMileage = function (value) {
        var mileage = undefined;
        var unlimitedStrKey = 'Unlimited';
        if (value && value.length > 0) {
            var end = value.substr(value.length - 1, 1);
            var prefix = value.substr(0, value.length - 1);
            if (value === unlimitedStrKey) {
                mileage = this.props.mapUnlimitedToMiles[unlimitedStrKey];
            }
            if (end === 'k') {
                mileage = parseInt(prefix + "000", 10);
            }
        }
        return mileage;
    };
    MenuProductDetails.prototype.stringToTerm = function (value) {
        return parseInt(value, 10);
    };
    MenuProductDetails.prototype.termToString = function (term) {
        if (term) {
            return term.toString();
        }
    };
    MenuProductDetails.prototype.deductibleToString = function (deductible) {
        // allow for zero deductible to pass logic check
        if (deductible !== null && deductible !== undefined) {
            return deductible.toString();
        }
    };
    MenuProductDetails.prototype.buildMileageList = function (mileages) {
        var _this = this;
        return mileages.map(function (mileage) { return _this.mileageToStringWithProps(mileage); });
    };
    MenuProductDetails.prototype.buildTermList = function (terms) {
        var _this = this;
        return terms.map(function (term) { return _this.termToString(term); });
    };
    MenuProductDetails.prototype.buildDeductibleList = function (deductibles) {
        var _this = this;
        this.props.selectedDeductible;
        return deductibles.map(function (deductible) { return "$" + _this.deductibleToString(deductible); });
    };
    MenuProductDetails.prototype.getParsedFullDescription = function (fullDetails) {
        var parsedFullDescription;
        try {
            var convertedBBCode_1 = [];
            var fullDetailsArray = JSON.parse(fullDetails);
            fullDetailsArray.forEach(function (arrayItem) {
                convertedBBCode_1.push(parser.toReact(arrayItem));
            });
            parsedFullDescription = convertedBBCode_1;
        }
        catch (e) {
            parsedFullDescription = fullDetails;
        }
        return parsedFullDescription;
    };
    MenuProductDetails.prototype.showCoverageNameIfExists = function () {
        var coverageName = this.props.coverageName;
        if (coverageName) {
            return (React.createElement("div", { className: "coverage-name-group" },
                React.createElement("div", { className: "button-group-label" }, "Coverage Name"),
                React.createElement("div", { className: "coverage-name" },
                    React.createElement("strong", null, coverageName))));
        }
        return null;
    };
    MenuProductDetails.prototype.showDeductibleIfExists = function () {
        var _a = this.props, selectedDeductible = _a.selectedDeductible, cardId = _a.cardId;
        if (selectedDeductible !== null) {
            // If data allows user to select from mutiple deductibles,
            // this array should be passed in via props just like miles.
            var deductibles = [selectedDeductible];
            var deductibleArray = this.buildDeductibleList(deductibles);
            return (React.createElement("div", { className: "deductible-group" },
                React.createElement("div", { className: "button-group-label" }, "Deductible"),
                React.createElement(RadioButtonGroup, { itemArray: deductibleArray, name: "deductible", cardId: cardId, selectedItem: null })));
        }
        return null;
    };
    MenuProductDetails.prototype.render = function () {
        var _this = this;
        var _a = this.props, errorMessage = _a.errorMessage, fullDetails = _a.fullDetails, isShowDetailsOpen = _a.isShowDetailsOpen, isRated = _a.isRated, cardId = _a.cardId, mileages = _a.mileages, plans = _a.plans, terms = _a.terms, selectedTerm = _a.selectedTerm, selectedMileage = _a.selectedMileage, selectedDeductible = _a.selectedDeductible, coverageName = _a.coverageName;
        if (isShowDetailsOpen) {
            var errorMessageContent = !errorMessage ? [] : (React.createElement("div", { className: "error" }, errorMessage));
            var ratedDetails = void 0;
            if (isRated) {
                // '65k', '75k', '85k', '100k', '125k'
                var milesArray = this.buildMileageList(mileages);
                // [36, 48, 60, 72, 84]
                var termsArray = this.buildTermList(terms);
                ratedDetails = (React.createElement("div", null,
                    React.createElement("div", { className: "" + (termsArray.length === 0 ? 'hidden' : 'terms-mileage-group') },
                        React.createElement("div", { className: "header" }, "Select Your Coverage Options"),
                        React.createElement("div", { className: "coverage-detail-container" },
                            this.showCoverageNameIfExists(),
                            React.createElement("div", { className: "button-group-container" },
                                React.createElement("div", { className: "months-group" },
                                    React.createElement("div", { className: "button-group-label" }, "Months"),
                                    React.createElement(RadioButtonGroup, { itemArray: termsArray, name: "term", cardId: cardId, selectedItem: this.termToString(selectedTerm), onChange: function (value) { _this.handleTermChange(value); } })),
                                React.createElement("div", { className: "miles-group" },
                                    React.createElement("div", { className: "button-group-label" }, "Miles"),
                                    React.createElement(RadioButtonGroup, { itemArray: milesArray, name: "mileage", cardId: cardId, selectedItem: this.mileageToStringWithProps(selectedMileage), onChange: function (value) { _this.handleMileageChange(value); } })),
                                this.showDeductibleIfExists())))));
            }
            else {
                ratedDetails = [];
            }
            return (React.createElement("div", null,
                errorMessageContent,
                ratedDetails,
                this.getParsedFullDescription(fullDetails)));
        }
        return null;
    };
    return MenuProductDetails;
}(Component));
export default MenuProductDetails;
