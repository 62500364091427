var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { GroupLineItemUI, LineItemUI } from './LineItemUI';
import { formatFees } from '../../utils/formatUtils';
var CashSummaryUI = /** @class */ (function (_super) {
    __extends(CashSummaryUI, _super);
    function CashSummaryUI(props) {
        var _this = _super.call(this, props) || this;
        _this.getTradeInLineItem = function () {
            var _a = _this.props, tradeInWithSign = _a.tradeInWithSign, tradeValue = _a.tradeValue;
            return tradeValue && tradeValue > 0 ? (React.createElement(LineItemUI, { key: "4", label: "Net Trade-in Value", value: tradeInWithSign, notAvailableText: "", id: "tradeAllowance" })) : null;
        };
        _this.getAdvertisedPriceLineItem = function () {
            var _a = _this.props, retailPrice = _a.retailPrice, vehicleCondition = _a.vehicleCondition, isSinglePrice = _a.isSinglePrice;
            var priceLabel;
            if (vehicleCondition === 'New') {
                priceLabel = 'MSRP';
            }
            if (vehicleCondition !== 'New' && !isSinglePrice) {
                priceLabel = 'Advertised Price';
            }
            return priceLabel ? (React.createElement("div", null,
                React.createElement(LineItemUI, { key: "0", label: priceLabel, value: formatFees(retailPrice), notAvailableText: "", id: "msrp" }),
                React.createElement("hr", { key: "1" }))) : null;
        };
        _this.getDealerFeeLineItems = function () {
            var dealerFees = _this.props.dealerFees;
            if (!dealerFees || dealerFees.length <= 0) {
                return null;
            }
            return dealerFees.map(function (fee, i) {
                return (React.createElement(LineItemUI, { key: 7 + i, label: fee.dealerFeeDesc.replace('&amp;', '&'), value: formatFees(fee.dealerFeeAmount), notAvailableText: "Not Included", id: "dealerFee_" + i }));
            });
        };
        _this.getSpecialsLineItem = function () {
            var special = _this.props.special;
            if (special) {
                return (React.createElement(LineItemUI, { key: "3", label: "Specials", value: special, id: "incentivesAmount" }));
            }
            return null;
        };
        return _this;
    }
    CashSummaryUI.prototype.render = function () {
        var _a = this.props, isSinglePrice = _a.isSinglePrice, cashOffer = _a.cashOffer, offerPrice = _a.offerPrice, dmvFees = _a.dmvFees, totalTax = _a.totalTax;
        var priceLabel = isSinglePrice ? 'My Price' : 'My Asking Price';
        return (React.createElement("div", { className: "dr-ui-summary-container" },
            React.createElement("div", { className: "summary-column" },
                this.getAdvertisedPriceLineItem(),
                React.createElement(LineItemUI, { key: "2", label: priceLabel, value: formatFees(offerPrice), notAvailableText: "", id: "offerPrice" }),
                this.getSpecialsLineItem(),
                this.getTradeInLineItem(),
                React.createElement(LineItemUI, { key: "5", label: "Taxes", value: formatFees(totalTax) || null, notAvailableText: "Not Included", id: "taxes" }),
                React.createElement(LineItemUI, { key: "6", label: "DMV Fees", value: formatFees(dmvFees) || null, notAvailableText: "Not Included", id: "dmvFees" }),
                this.getDealerFeeLineItems(),
                React.createElement("hr", { key: "20" }),
                React.createElement(GroupLineItemUI, { label: "Est. Cash Payment", value: formatFees(cashOffer), notAvailableText: "Not Available", id: "totalCash" }))));
    };
    return CashSummaryUI;
}(React.Component));
export default CashSummaryUI;
