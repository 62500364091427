export var ROUTE_CREDIT_APP = 'ROUTE_CREDIT_APP';
export var ROUTE_CREDIT_RESULTS = 'ROUTE_CREDIT_RESULTS';
export var ROUTE_DEAL_SUMMARY = 'ROUTE_DEAL_SUMMARY';
export var ROUTE_TEST_DRIVE = 'ROUTE_TEST_DRIVE';
export var ROUTE_HOME = 'ROUTE_HOME';
export var ROUTE_BACK = 'ROUTE_BACK';
// credit app actions
export var LEAD_REFERENCE_TOKEN_REQUEST = 'LEAD_REFERENCE_TOKEN_REQUEST';
export var LEAD_REFERENCE_TOKEN_SUCCESS = 'LEAD_REFERENCE_TOKEN_SUCCESS';
export var LEAD_REFERENCE_TOKEN_FAILURE = 'LEAD_REFERENCE_TOKEN_FAILURE';
export var OFFER_SUBMISSION_SUCCESS = 'OFFER_SUBMISSION_SUCCESS';
export var UPDATE_CREDIT_APP = 'UPDATE_CREDIT_APP';
export var CANCEL_CREDIT_APP = 'CANCEL_CREDIT_APP';
