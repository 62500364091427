import * as React from 'react';
// tslint:disable:max-line-length
export var HertzDisclaimerContentUI = function (props) {
    return (React.createElement("div", null,
        React.createElement("h5", null, "Last updated on June 8, 2018"),
        React.createElement("div", null,
            React.createElement("h3", null, "Trade-in Values")),
        React.createElement("div", null, "Third party Trade-In Values may be provided by the dealership.  All vehicles are subject to inspection, any values provided prior to inspection are estimates used for information purposes only and are not a commitment to purchase your vehicle.  For Kelley Blue Book\u00AE Trade-In Values, the specific information required to determine the value for a particular vehicle is information supplied by the person generating this report. Vehicle valuations may vary based upon market conditions, vehicle specifications, vehicle condition or other circumstances pertinent to this vehicle. Trade-in Values are intended for the individual use of the person generating this report only and shall not be sold or transmitted to another party. Kelley Blue Book assumes no responsibility for errors or omissions. \u00A92018 Kelley Blue Book Co., Inc. All Rights Reserved."),
        React.createElement("div", null,
            React.createElement("h3", null, "Negative Trade Equity (Amount Owed)")),
        React.createElement("div", null, "Negative Equity occurs when the balance owed on your current vehicle trade-in exceeds the value of your vehicle estimated by the dealer. When you apply negative equity to your amount financed, the lender may have a limit to the amount you can finance. In some cases, the lender may only allow you to finance part of the negative equity. An additional down payment may be required by the lender. Ask the dealer or lender for more details."),
        React.createElement("div", null,
            React.createElement("h3", null, "Credit / Financing")),
        React.createElement("div", null, "All financing terms and conditions shown are estimates and actual financing terms are contingent upon completion of a credit application and decision by the dealer and/or lender. Annual Percentage Rates are estimates based on the credit score provided by you. Your down payment, trade-in and any additional options may impact the Amount Financed and monthly payment. The financing terms vary based upon credit score and length of term. The payment and financing information provided here is not an offer or a commitment by any organization to provide credit.  Additional down payment may be required.. Other terms and conditions may apply. Ask Dealer for details or questions about how to start your application."),
        React.createElement("div", null,
            React.createElement("h3", null, "Estimated Monthly Payments")),
        React.createElement("div", null, "The Estimated Monthly Payment is calculated  using estimates  to determine the Total Amount Financed. These estimates include Taxes, Trade-In Values, Down Payment, Applicable Fees, and your Credit/Financing terms."),
        React.createElement("div", null,
            React.createElement("h3", null, "Read our Hertz Privacy Policy")),
        React.createElement("a", { href: "https://www.hertzcarsales.com/privacypolicy" }, "https://www.hertzcarsales.com/privacypolicy")));
};
