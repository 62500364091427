var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import * as PropTypes from 'prop-types';
var propTypes = {
    iconClass: PropTypes.string.isRequired,
    onMouseEnter: PropTypes.func,
    onClick: PropTypes.func
};
var TooltipIconUI = function (props) {
    var attributes = {
        className: "tooltip-container " + props.iconClass
    };
    if (typeof props.onClick === 'function') {
        attributes.onClick = props.onClick;
    }
    if (typeof props.onMouseEnter === 'function') {
        attributes.onMouseEnter = props.onMouseEnter;
    }
    return (React.createElement("div", __assign({}, attributes)));
};
TooltipIconUI.propTypes = propTypes;
export default TooltipIconUI;
