var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Component } from 'react';
var CardActionButtonUI = /** @class */ (function (_super) {
    __extends(CardActionButtonUI, _super);
    function CardActionButtonUI() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CardActionButtonUI.prototype.handleClick = function () {
        if (this.props.onClick) {
            this.props.onClick();
        }
    };
    CardActionButtonUI.prototype.getButtonContent = function () {
        if (this.props.isSelected) {
            return React.createElement("i", { className: "fa fa-check" });
        }
        else {
            return React.createElement("span", null, this.props.buttonText);
        }
    };
    CardActionButtonUI.prototype.render = function () {
        var _this = this;
        return (React.createElement("div", { className: "dr-ui-package-price-add-btn" },
            React.createElement("button", { disabled: this.props.disabled, className: "btn btn-secondary btn-sm btn-block", type: "button", onClick: function () { _this.handleClick(); } }, this.getButtonContent())));
    };
    CardActionButtonUI.propTypes = {};
    return CardActionButtonUI;
}(Component));
export default CardActionButtonUI;
