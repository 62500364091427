import { connect } from 'react-redux';
import { selectors } from '@makemydeal/dr-offer-redux';
import { isValidDollarAmount, formatDollars, formatTermMonths, formatApr, formatRetailPrice, formatMiles } from '../../utils/formatUtils';
import SummaryUI from './SummaryUI';
export var mapStateToProps = function (state, ownProps) {
    var useNewLeaseVerbiage = ownProps && ownProps.useNewLeaseVerbiage;
    var offerSummary = selectors.getOfferSummaryByUserSelectedOfferType(state);
    var amountFinanced = isValidDollarAmount(offerSummary.amountFinanced) ? formatDollars(offerSummary.amountFinanced) : null;
    var annualMiles = offerSummary.annualMiles ? formatMiles(offerSummary.annualMiles) : 0;
    var apr = offerSummary.term.apr ? formatApr(offerSummary.term.apr) : null;
    var downPayment = isValidDollarAmount(offerSummary.downPayment) ? formatDollars(offerSummary.downPayment) : null;
    var listedPrice = isValidDollarAmount(offerSummary.listedPrice) ? formatDollars(offerSummary.listedPrice) : null;
    var offerPrice = isValidDollarAmount(offerSummary.offerPrice) ? formatDollars(offerSummary.offerPrice) : null;
    var retailPrice = isValidDollarAmount(offerSummary.retailPrice) ? formatRetailPrice(offerSummary.retailPrice) : null;
    var termMonths = formatTermMonths(offerSummary.term.months);
    var totalIncentives = isValidDollarAmount(offerSummary.totalIncentives) ? formatDollars(offerSummary.totalIncentives) : null;
    var financeDownPayment = (offerSummary.downPayment > 0) ? "-" + downPayment : null;
    var specials = (offerSummary.totalIncentives > 0) ? "-" + totalIncentives : null;
    var totalDueAtSigning = isValidDollarAmount(offerSummary.dueAtSigning) ? formatDollars(offerSummary.dueAtSigning) : null;
    var netTradeIn = offerSummary.tradeInValue - offerSummary.tradeInAmountOwed;
    var absoluteTradeIn = (netTradeIn > 0) ? netTradeIn : (-1) * netTradeIn;
    var formattedNetTradeIn = isValidDollarAmount(absoluteTradeIn) ? formatDollars(absoluteTradeIn) : null;
    // Display a positive trade-in with a negative sign and vice-versa (no worry: it's pure business)
    var positiveTradeIn = (netTradeIn > 0) ? "-" + formattedNetTradeIn : null;
    var financeNegativeTradeIn = offerSummary.isFinanceNegativeTradeIn ? "+" + formattedNetTradeIn : null;
    var dueAtSigningNegativeTradeIn = netTradeIn && !(positiveTradeIn || financeNegativeTradeIn) ?
        "+" + formattedNetTradeIn
        : null;
    var vehicle = selectors.getVehicleDetails(state);
    var vehicleType = vehicle.type || vehicle.condition || null;
    var offerType = selectors.getCurrentOfferType(state);
    var isFinanceOfferType = offerType === 'finance';
    var isLeaseOfferType = offerType === 'lease';
    var aprNotAvailableText;
    var downPaymentText = 'Down Payment';
    var dueAtSigningText = 'Total Due At Signing';
    var notAvailableText = 'Not Available';
    var termNotAvailableText = 'Term not available';
    if (isFinanceOfferType) {
        aprNotAvailableText = 'APR not available';
    }
    else {
        apr = null;
    }
    if (isLeaseOfferType && useNewLeaseVerbiage) {
        downPaymentText = 'Cap Cost Reduction';
        dueAtSigningText = 'Cash Down (Out of Pocket)';
    }
    return {
        advertisedPriceLabel: (ownProps && ownProps.advertisedPriceLabel) || 'Advertised Price',
        amountFinanced: amountFinanced,
        annualMiles: annualMiles,
        apr: apr,
        aprNotAvailableText: aprNotAvailableText,
        downPayment: downPayment,
        downPaymentText: downPaymentText,
        dueAtSigningNegativeTradeIn: dueAtSigningNegativeTradeIn,
        dueAtSigningText: dueAtSigningText,
        financeDownPayment: financeDownPayment,
        financeNegativeTradeIn: financeNegativeTradeIn,
        listedPrice: listedPrice,
        notAvailableText: notAvailableText,
        offerPrice: offerPrice,
        positiveTradeIn: positiveTradeIn,
        retailPrice: retailPrice,
        isFinanceOfferType: isFinanceOfferType,
        specials: specials,
        specialsLabel: ownProps.specialsLabel,
        termMonths: termMonths,
        termNotAvailableText: termNotAvailableText,
        totalDueAtSigning: totalDueAtSigning,
        vehicleType: vehicleType
    };
};
var Summary = connect(mapStateToProps)(SummaryUI);
export default Summary;
