// tslint:disable-next-line:import-name
import CashDealSummaryContainer from './CashSummary';
// tslint:disable-next-line:import-name
import CashDealSummaryUI from './CashSummaryUI';
// tslint:disable-next-line:import-name
import DealSummaryContainer from './Summary';
// tslint:disable-next-line:import-name
import DealSummaryUI from './SummaryUI';
// tslint:disable-next-line:import-name
import ServicesSummaryContainer from './ServicesSummary';
import ServicesSummaryUI from './ServicesSummaryUI';
export var CashDealSummary = {
    Container: CashDealSummaryContainer,
    UI: CashDealSummaryUI
};
export var DealSummary = {
    Container: DealSummaryContainer,
    UI: DealSummaryUI
};
export var ServicesSummary = {
    Container: ServicesSummaryContainer,
    UI: ServicesSummaryUI
};
