import { connect } from 'react-redux';
import { selectors } from '@makemydeal/dr-offer-redux';
import { getIsDueAtSigninAmountDeduct } from './selectors';
import ServicesSummaryUI from './ServicesSummaryUI';
import { routingActionCreator } from './actions/routingActionCreators';
var mapStateToProps = function (state, ownProps) {
    var offerType = selectors.getCurrentOfferType(state);
    var isLeaseOfferType = offerType === 'lease';
    var useNewLeaseVerbiage = ownProps && ownProps.useNewLeaseVerbiage;
    var offerSummary = selectors.getOfferSummaryByUserSelectedOfferType(state);
    var dealSummary = offerSummary.dealSummary;
    var isDealSummaryDisplayed = selectors.isDealSummaryDisplayed(state);
    var downPaymentText = 'Down Payment';
    var downPaymentTextSecond = 'Down Payment'; // Lease Cap Cost logic changes this label but leaves the above label as is
    var dueAtSigningText = 'Total Due At Signing';
    if (isLeaseOfferType && useNewLeaseVerbiage) {
        var isDueAtSigninAmountDeduct = getIsDueAtSigninAmountDeduct(state);
        downPaymentText = 'Cap Cost Reduction';
        dueAtSigningText = 'Due At Signing';
        downPaymentTextSecond = isDueAtSigninAmountDeduct ? 'Cap Cost Reduction' : 'Cash Down';
    }
    return {
        dealSummary: dealSummary,
        downPaymentText: downPaymentText,
        downPaymentTextSecond: downPaymentTextSecond,
        dueAtSigningText: dueAtSigningText,
        advertisedPriceLabel: ownProps.advertisedPriceLabel || 'Advertised Price',
        specialsLabel: ownProps.specialsLabel === undefined ? 'Specials' : ownProps.specialsLabel,
        isDealSummaryDisplayed: isDealSummaryDisplayed
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        onAdjustedCashInfoClick: function (route) {
            return dispatch(routingActionCreator(route));
        }
    };
};
var ServicesSummary = connect(mapStateToProps, mapDispatchToProps)(ServicesSummaryUI);
export default ServicesSummary;
