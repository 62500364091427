import * as React from 'react';
import { StandardVisitorAgreementContentUI } from './content/StandardVisitorAgreementUI';
import { HertzVisitorAgreementContentUI } from './content/HertzVisitorAgreementUI';
// NOTE: In future this could be exported from this component if it is needed elsewhere
var HERTZ_TEMPLATE_NAME = 'hertz';
var VisitorAgreementUI = function (props) {
    var VisitorAgreementContentUI;
    switch (props.templateName) {
        case HERTZ_TEMPLATE_NAME:
            VisitorAgreementContentUI = HertzVisitorAgreementContentUI;
            break;
        default:
            VisitorAgreementContentUI = StandardVisitorAgreementContentUI;
            break;
    }
    return (React.createElement("div", { className: "Disclaimer-container" },
        React.createElement(VisitorAgreementContentUI, { dealerName: props.dealerName })));
};
export default VisitorAgreementUI;
