import { connect } from 'react-redux';
import MenuProductCardUI from './MenuProductCardUI';
var mapStateToProps = function (state) {
    return {};
};
var mapDispatchToProps = function (dispatch) {
    return {};
};
var MenuProductCardContainer = connect(mapStateToProps, mapDispatchToProps)(MenuProductCardUI);
export default MenuProductCardContainer;
