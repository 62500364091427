var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Component } from 'react';
import * as Types from './actions/actionTypes';
var propTypes = {};
var InfoIconUI = /** @class */ (function (_super) {
    __extends(InfoIconUI, _super);
    function InfoIconUI(props) {
        return _super.call(this, props) || this;
    }
    InfoIconUI.prototype.buildIcon = function (classes, route) {
        var _this = this;
        var className = "dr-ui-info-btn " + classes;
        var onClick = function () { return _this.props.onIconClick(route); };
        return (React.createElement("div", { className: className, onClick: onClick }));
    };
    InfoIconUI.prototype.showIcon = function () {
        var pathname = this.props.pathname;
        var icon;
        switch (pathname) {
            case 'dealSummary':
                icon = null;
                break;
            case 'viewOffer':
                icon = this.buildIcon('info-close', Types.ROUTE_BACK);
                break;
            default:
                icon = this.buildIcon('info-circle', Types.ROUTE_VIEW_OFFER);
        }
        return icon;
    };
    InfoIconUI.prototype.render = function () {
        return (React.createElement("div", { className: "dr-ui-info-btn" }, this.showIcon()));
    };
    InfoIconUI.defaultProps = {};
    return InfoIconUI;
}(Component));
export default InfoIconUI;
