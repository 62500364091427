import { connect } from 'react-redux';
import ActionCardUI from './ActionCardUI';
import { routingActionCreator } from './actions/routingActionCreators';
var mapStateToProps = function (state) {
    return {
        offer: state.offer,
        creditApp: state.creditApp,
        init: state.init
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        onCardClick: function (route) { return dispatch(routingActionCreator(route)); }
    };
};
var ActionCardContainer = connect(mapStateToProps, mapDispatchToProps)(ActionCardUI);
export default ActionCardContainer;
