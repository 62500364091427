var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Component } from 'react';
var LoadingButtonUI = /** @class */ (function (_super) {
    __extends(LoadingButtonUI, _super);
    function LoadingButtonUI() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    LoadingButtonUI.prototype.handleClick = function () {
        if (this.props.onClick) {
            this.props.onClick();
        }
    };
    LoadingButtonUI.prototype.render = function () {
        var _this = this;
        var loadingText = this.props.sendingText || 'Sending';
        var continueButtonLoadingIcon = (React.createElement("div", { className: "dr-ui-loading-button-group" },
            React.createElement("span", { className: "dr-ui-loading-button-form-submiting-indicator-text" }, loadingText),
            React.createElement("div", { className: "dr-ui-loading-button-spinner" })));
        var buttonType = this.props.isSubmit ? 'submit' : 'button';
        var id = this.props.id ? this.props.id : 'dr-ui-loading-button';
        return (React.createElement("button", { disabled: this.props.disabled || this.props.isPending, className: "btn btn-primary " + this.props.additionalClassParams, id: id, type: buttonType, onClick: function () { _this.handleClick(); } }, this.props.isPending ? continueButtonLoadingIcon : this.props.buttonText));
    };
    LoadingButtonUI.propTypes = {
        validateOnClick: function (props, propName, componentName) {
            if ((props['isSubmit'] !== true) && props['onClick'] === undefined) {
                return new Error('Please provide a onClick function when isSubmit is not true');
            }
        }
    };
    return LoadingButtonUI;
}(Component));
export default LoadingButtonUI;
