import * as React from 'react';
import { StandardPrivacyPolicyContentUI } from './content/StandardPrivacyPolicyUI';
import { HertzPrivacyPolicyContentUI } from './content/HertzPrivacyPolicyUI';
// NOTE: In future this could be exported from this component if it is needed elsewhere
var HERTZ_TEMPLATE_NAME = 'hertz';
var PrivacyPolicyUI = function (props) {
    var PrivacyPolicyContentUI;
    switch (props.templateName) {
        case HERTZ_TEMPLATE_NAME:
            PrivacyPolicyContentUI = HertzPrivacyPolicyContentUI;
            break;
        default:
            PrivacyPolicyContentUI = StandardPrivacyPolicyContentUI;
            break;
    }
    return (React.createElement("div", { className: "Disclaimer-container" },
        React.createElement(PrivacyPolicyContentUI, { dealerName: props.dealerName })));
};
export default PrivacyPolicyUI;
