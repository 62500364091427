import * as React from 'react';
/* tslint:disable:max-line-length */
export var StandardVisitorAgreementContentUI = function (props) {
    return (React.createElement("div", { className: "VisitorAgreement-container" },
        React.createElement("h1", { className: "dr-ui-legal-docs-headline" }, "Visitor Agreement"),
        React.createElement("div", null,
            props.dealerName,
            " uses an experience on this website that is provided by one of our technology partners who provides the display of such items that may contain Payments, Credit Terms, Trade-In Values, and other tools that help provide a better shopping experience.  The use of this experience and the visitor agreement is governed by their visitor agreement below:"),
        React.createElement("hr", null),
        React.createElement("h3", null, "Autotrader Visitor Agreement"),
        React.createElement("p", null, "Effective Date: May 30, 2017"),
        React.createElement("div", null, "Thank you for your interest in Autotrader. In this Visitor Agreement, the terms \"Autotrader,\" \"we,\" \"us,\" and \"our\" refer to Autotrader.com, Inc. and the terms \"you\" and \"your\" refer to you as a user of our websites, mobile and other online applications and products and services (collectively, the \"Autotrader Sites\")."),
        React.createElement("dl", { className: "dl-horizontal legal-notation" },
            React.createElement("dt", null, "I."),
            React.createElement("dd", null,
                React.createElement("h5", null, "General Provisions")),
            React.createElement("dt", { className: "indent" }, "A."),
            React.createElement("dd", null,
                React.createElement("p", null,
                    "By using the Autotrader Sites, you accept the terms of this Visitor Agreement. This is a legally binding agreement between you and Autotrader; please read it carefully. When using the Autotrader Sites, you may be subject to other posted terms and guidelines applicable to certain services available on or through the Autotrader Sites. All terms and guidelines on the Autotrader Sites, including our",
                    React.createElement("a", { href: "https://www.autotrader.com/privacy.jsp" }, "\u00A0Privacy Statement"),
                    ", are part of this Visitor Agreement and incorporated herein by reference. Unless explicitly stated otherwise, any features or services available at any time on the Autotrader Sites are subject to this Visitor Agreement. Accessing the Autotrader Sites in any manner, even through automated means, constitutes your use of the Autotrader Sites and your agreement to be bound by this Visitor Agreement. Autotrader may change the terms of this Visitor Agreement from time to time and will revise the effective date when it does so. Your continued use of the Autotrader Sites after the posted effective date constitutes your agreement to be bound by this Visitor Agreement as modified, except that modifications do not apply to any dispute arising prior to their effective date. Autotrader may change, restrict access to, suspend, or discontinue the Autotrader Sites, or any portion thereof.")),
            React.createElement("dt", { className: "indent" }, "B."),
            React.createElement("dd", null,
                React.createElement("p", null, "The material that appears on the Autotrader Sites is for general informational purposes only. While we aim to provide a site that is useful, be mindful that the Autotrader Sites may, from time to time, contain errors. The Autotrader Sites includes materials and information collected from and provided by third parties that we may not have evaluated or reviewed. We make no guarantees regarding the accuracy, completeness, timeliness, or reliability of any of the materials or information on the Autotrader Sites, and you should not rely on it without independent verification.")),
            React.createElement("dt", { className: "indent" }, "C."),
            React.createElement("dd", null,
                React.createElement("p", null,
                    "We invite you to bring to our attention any materials or information on the Autotrader Sites that you believe to be inaccurate. Please forward a copy of the materials or information to our",
                    React.createElement("a", { href: "https://www.autotrader.com/help/email.xhtml?contact_email=atc_info" }, "\u00A0Customer Service Manager"),
                    ", along with an explanation of the inaccuracies.")),
            React.createElement("dt", null, "II."),
            React.createElement("dd", null,
                React.createElement("h5", null, "Buying and Selling Vehicles")),
            React.createElement("dt", { className: "indent" }, "A."),
            React.createElement("dd", null,
                React.createElement("p", null, "The Autotrader Sites is a vehicle listing and information service that brings together buyers and sellers. Autotrader is not a party to any transaction between vehicle buyers and sellers that originates on or through the Autotrader Sites. Information about a particular vehicle is supplied by the seller, not by Autotrader. The price and other terms of any sale remain subject to direct negotiation between the buyer and the seller.")),
            React.createElement("dt", { className: "indent" }, "B."),
            React.createElement("dd", null,
                React.createElement("p", null, "Though we hope that all who come to the Autotrader Sites will act honorably and treat each other fairly, we cannot verify the information that sellers supply or guarantee the vehicles they offer. Nor can we assure the seller of a vehicle that the payment he or she receives from the buyer is legitimate. When using the Autotrader Sites to find a buyer for your vehicle or a vehicle to purchase, we urge you to use the same common sense and good judgment you would use in selling a vehicle through, or responding to, a classified ad in the newspaper.")),
            React.createElement("dt", { className: "indent" }, "C."),
            React.createElement("dd", null,
                React.createElement("p", null, "Please read our fraud awareness tips before you buy or list a vehicle on the Autotrader Sites. There is no substitute for healthy skepticism and your own good judgment.")),
            React.createElement("dt", { className: "indent" }, "D."),
            React.createElement("dd", null,
                React.createElement("p", null, "Buying a Vehicle")),
            React.createElement("dt", { className: "indent-2" }, "1."),
            React.createElement("dd", null,
                React.createElement("p", null, "The prices listed by sellers on the Autotrader Sites often exclude sales tax, finance charges, title, license, regulatory, dealer documentary, emission testing, and compliance fees, any or all of which may be added to the listed price to arrive at the final sale price of a particular vehicle. Before purchasing a vehicle or any other good or service you have read about on the Autotrader Sites, you should confirm with the seller any information, including the price that is important to your purchasing decision. Autotrader is not responsible for, and does not guarantee the performance of, any such vehicles, goods or services listed or researched on the Autotrader Sites.")),
            React.createElement("dt", { className: "indent-2" }, "2."),
            React.createElement("dd", null,
                React.createElement("p", null, "We may screen some email messages sent through the Autotrader Sites to sellers about vehicles listed on the Autotrader Sites. If it appears that your email message has not reached the seller of the vehicle you are inquiring about, you might try inquiring by telephone if the seller has provided a telephone number.")),
            React.createElement("dt", { className: "indent-2" }, "3."),
            React.createElement("dd", null,
                React.createElement("p", null, "Our used car listings include vehicles that have been \"certified\" as meeting certain standards established by manufacturers and/or dealers in connection with their pre-owned vehicle programs. The decision to certify any particular vehicle is made by the certifying manufacturer or dealer, and the vehicle information included in the Autotrader Sites' certified vehicle listing is provided by the listing dealer. Neither Autotrader nor the manufacturer is responsible for the accuracy of any information contained in a certified vehicle listing. Only the party that certifies a vehicle is responsible for the decision to certify that vehicle. You should familiarize yourself with the terms of the applicable certification program before buying a certified vehicle. Ads purchased by private sellers through our Sell Your Car service will not appear in any \"certified\" area of the Autotrader Sites. Ads for certified vehicles apply only to vehicles certified by dealers in accordance with the certification programs of the automobile manufacturers with which we have partnerships.")),
            React.createElement("dt", { className: "indent-2" }, "4."),
            React.createElement("dd", null,
                React.createElement("p", null, "Certain dealers who participate in an anonymous chat or anonymous texting service provided by Autotrader or its affiliates may offer you an option to inquire about a listed vehicle without disclosing your personal information (such as your name, e-mail address or telephone number). If you contact a dealer via an anonymous text, the dealer will be able to respond to that text and contact you without having access to your mobile number. Both you and the dealer have the option to end such chat or text conversation at any time. If, in the course of such chat or text conversation, you supply to the dealer your email address and/or telephone number, you consent to receive e-mail communications to such e-mail address and calls and texts at that number, whether manually or automatically generated. You are under no obligation to provide your name or contact information over the course of such chat or text conversation.")),
            React.createElement("dt", { className: "indent-2" }, "5."),
            React.createElement("dd", null,
                React.createElement("p", null, "Anonymous chat and text service relies on infrastructure and services furnished by third-party providers. Neither Autotrader, nor its affiliates, nor participating dealers warrant the continuous availability or error-free operation of the anonymous chat and text services.")),
            React.createElement("dt", { className: "indent" }, "E."),
            React.createElement("dd", null,
                React.createElement("p", null, "Listing Your Vehicle")),
            React.createElement("dt", { className: "indent-2" }, "1."),
            React.createElement("dd", null,
                React.createElement("p", null,
                    "The Autotrader Sites offers a variety of ad packages to private sellers, with combinations of features that may vary by market area. For information about the ad packages available to private sellers in your area, click the \"Sell Your Car\" tab or button on our home page. On the next page, enter your ZIP code to view our product offerings. For information about ad packages available to dealers (and private sellers wishing to list more than five vehicles for sale at a given time), please visit",
                    React.createElement("a", { href: "http://www.weworkforyou.com/?utm_source=AutoTrader%2Ccom&utm_medium=VisitorsAgreement&utm_campaign=VisitorsAgreement" }, "\u00A0weworkforyou.com"),
                    ".")),
            React.createElement("dt", { className: "indent-2" }, "2."),
            React.createElement("dd", null,
                React.createElement("p", null, "In order for you to offer a vehicle for sale through our Sell Your Car service for private sellers, you must agree to our Terms of Sale. The Terms of Sale require, among other things, that you be prepared to sell your vehicle at the price at which, and on the terms on which, you have listed it. You must have possession of the actual vehicle listed and the ability to transfer title. To list a vehicle for sale on the Autotrader Sites, sellers also are required to provide certain identifying and contact information. The information must accurately identify the seller and the method of contact must permit buyers to communicate directly with the seller. You may not charge any potential buyer for information about any vehicle listed for sale on the Autotrader Sites, nor may you use the Autotrader Sites to promote, without our prior written permission, any other website, product, or service.")),
            React.createElement("dt", { className: "indent-2" }, "3."),
            React.createElement("dd", null,
                React.createElement("p", null,
                    "By using the Autotrader Sites to sell your vehicle as a private seller, you represent that you are at least 18 years of age, that you are not a motor vehicle dealer, that you are not listing a vehicle for sale in your capacity as an owner, employee or representative of a dealer, and that neither you nor anyone acting on your behalf will list more than five vehicles for sale simultaneously. A private seller who wishes to list more than five vehicles simultaneously, and any commercial dealer wishing to list any vehicle, must make arrangements with us before doing so. Please visit",
                    React.createElement("a", { href: "http://www.weworkforyou.com/?utm_source=AutoTrader%2Ccom&utm_medium=VisitorsAgreement&utm_campaign=VisitorsAgreement" }, "\u00A0weworkforyou.com"),
                    " for information.")),
            React.createElement("dt", { className: "indent-2" }, "4."),
            React.createElement("dd", null,
                React.createElement("p", null,
                    "When you list a vehicle for sale through our Sell Your Car service, we may obtain additional distribution for your listing through our relationships with other websites, including KBB.com, Univision, MSN Autos, MSN Latino, and NADA Guides. We also may promote your listing on or through third-party websites or services. Neither Autotrader nor any website through which we distribute or promote your listing will charge you any additional fee for this additional exposure. It is simply added value from Autotrader. Our promotional partners will not contact you with offers to sell you additional distribution or add-on advertising services for your listing without your permission. If third parties contact you without your permission offering to sell you such services, they have likely misused the Autotrader Sites and violated this Visitor Agreement to exploit your listing information. If you receive any such solicitations that you did not authorize, please bring them to our attention by using our",
                    React.createElement("a", { href: "https://www.autotrader.com/help/email.jsp?contact_email=atc_info" }, "\u00A0contact us form"),
                    ".")),
            React.createElement("dt", { className: "indent-2" }, "5."),
            React.createElement("dd", null,
                React.createElement("p", null,
                    "Listings are not to be used to advertise more than one vehicle, regardless of a listing's duration. For this reason, you cannot edit your vehicle's year, make, model, or VIN once you have purchased a listing. Always double-check this information before purchasing a listing. Listing fees are generally not refundable, so if you enter incorrect information in the year, make, model, or VIN fields, you will have to delete the listing and purchase a new one to create an accurate listing. By using the Autotrader Sites to sell your vehicle, you agree to pay the price of the package you select and the prices of any advertising upgrades, regardless of whether your vehicle sells as a result of the listing. You also agree to pay any applicable taxes relating to your use of the Sell Your Car service. Click here for our",
                    React.createElement("a", { href: "" }, "\u00A0Refund Policy"),
                    ".")),
            React.createElement("dt", { className: "indent-2" }, "6."),
            React.createElement("dd", null,
                React.createElement("p", null, "Responsibility for the information contained in each listing lies with each seller. You alone are responsible for the material you post, including listing information and photos of your vehicle, and for the content of all email messages you transmit through the Autotrader Sites. Nothing will undermine a user's confidence in the vehicle you are looking to sell faster than inaccurate statements or misleading representations about the vehicle. And since any erosion of user confidence in you is likely to be accompanied by erosion of user confidence in us, we care deeply about making sure that the information you supply is accurate and that, in all respects, you treat other users of the Autotrader Sites fairly and honorably.")),
            React.createElement("dt", { className: "indent-2" }, "7."),
            React.createElement("dd", null,
                React.createElement("p", null, "In connection with our efforts to combat Internet fraud, some listings may be screened before being posted publicly. This process may delay the publication of your listing. Though we cannot monitor every transaction that originates through a listing on the Autotrader Sites, we may, from time to time, perform random quality assurance tests to confirm that those who offer vehicles for sale over the Autotrader Sites are prepared to sell those vehicles at the prices and on the terms which such vehicles are advertised. By using the Autotrader Sites, you agree to cooperate in these quality assurance tests. If our tests reveal, or we otherwise learn, that a seller may be violating the terms of this Visitor Agreement, we reserve the right to deny that seller use of services offered on the Autotrader Sites and any affiliated websites and remove that seller's listings from each site.")),
            React.createElement("dt", { className: "indent-2" }, "8."),
            React.createElement("dd", null,
                React.createElement("p", null, "By listing a vehicle for sale on the Autotrader Sites, you agree to use the email addresses of those responding to your listing only to communicate with them about the potential sale of that vehicle.")),
            React.createElement("dt", null, "III."),
            React.createElement("dd", null,
                React.createElement("h5", null, "Kelley Blue Book Instant Cash Offer Program Terms and Conditions")),
            React.createElement("dt", { className: "indent" }, "A."),
            React.createElement("dd", null,
                React.createElement("p", null, "Program Overview. With the Kelley Blue Book Instant Cash Offer Program (\u201CInstant Cash Offer Program\u201D) you can complete a questionnaire online or at a dealership and get a specific offer to buy your car for cash today (\"Instant Cash Offer\" or \"Offer\"). You can redeem your Instant Cash Offer for cash, or put the value of your Offer toward the purchase of another vehicle, subject to these Instant Cash Offer Program Terms and Conditions, the KBB.com Terms of Service including but not limited to the Arbitration and Class Action Waiver provisions, all of which are incorporated herein by reference, and other terms and conditions provided through the Instant Cash Offer Program. Kelley Blue Book and Autotrader do not redeem Instant Cash Offers or purchase vehicles; rather, the Instant Cash Offer is redeemable at dealers who have paid Kelley Blue Book or an affiliate to participate in the Instant Cash Offer Program (\"Participating Dealers\"). The Instant Cash Offer is valid at any Participating Dealer for three (3) days (not counting Sundays) after it was issued. It is important to note that the Instant Cash Offer is based on specific information about your vehicle, which may result in variations from the information collected in connection with a Kelley Blue Book Value. The Offer may be less than the Kelley Blue Book Trade-In Value and may be lower than the Trade-In Range for a similar vehicle.")),
            React.createElement("dt", { className: "indent" }, "B."),
            React.createElement("dd", null,
                React.createElement("p", null, "Your Vehicle must pass a mandatory vehicle inspection by a Participating Dealer. If the inspection report differs from your description or online assessment of your vehicle's condition, the Participating Dealer may adjust the Offer amount, which may mean decreasing the Offer amount. Participating dealers are not owned or operated by, nor are they affiliated with or acting on behalf of, either Kelley Blue Book or Autotrader. Kelley Blue Book, Autotrader, and their affiliates expressly disclaim any liability resulting from an adjustment of the Offer or refusal to accept the vehicle by Participating Dealer(s).")),
            React.createElement("dt", { className: "indent" }, "C."),
            React.createElement("dd", null,
                React.createElement("p", null, "Program Requirements. In addition to the other KBB.com Terms of Service and other terms to redeem an Instant Cash Offer that you have received through the Instant Cash Offer Program: (1) your vehicle must be located in a market in which the Instant Cash Offer Program is offered; (2) you must be the registered owner of the vehicle in question; (3) you must possess a valid driver's license or other form of valid government-issued photo ID; (4) you must not be an automobile dealer or an employee or agent of an automobile dealer; (5) the personal contact information and the information about your vehicle that you provide in response to our online questionnaire must be accurate, complete, and truthful; and (6) within three (3) days of the issuance of your Instant Cash Offer (not counting Sundays), you must deliver your vehicle, with a valid registration and either clear and unencumbered title in your name, or complete documentation regarding any lease obligations or liens on your vehicle (including, for example, contact information for your lender or leasing company, relevant account information, and the current loan payoff amount or lease early termination fee for your vehicle) to a Participating Dealer. The Participating Dealer will inspect your vehicle to confirm its condition and verify the accuracy of the other information about your vehicle that you submitted online. Based on the results of this verification inspection (more information below) reported by the Participating Dealer, the dollar amount of your Instant Cash Offer may be adjusted up or down prior to redemption.")),
            React.createElement("dt", { className: "indent" }, "D."),
            React.createElement("dd", null,
                React.createElement("p", null, "Restrictions. The Instant Cash Offer Program is not available in all areas and not all vehicles are eligible for Offers. The Instant Cash Offer Program may not be used to sell or to trade in certain categories of vehicles at Kelley Blue Book's discretion, including, without limitation: current model year (or newer) vehicles; exotic vehicles; commercial vehicles; vehicles subject to recall or investigation by a government agency; vehicles with a police, fire, livery, taxi, or rental history; reconstructed or salvage vehicles; vehicles without a valid Vehicle Identification Number (VIN); vehicles not originally built for the United States market or that were imported illegally or outside of official manufacturer channels (i.e., \"gray market\" vehicles); vehicles older than 24 model years; vehicles that have over 300,000 miles on the odometer; vehicles with altered drivelines or bodies or illegal or non-functioning emissions equipment; vehicles registered at an auto auction or offered for sale through any wholesale channel within 45 days; and vehicles that we would value at less than $1,000. You may use the Instant Cash Offer Program to sell or trade in a maximum of three vehicles in any six-month period. Vehicles with an Offer value greater than $75,000 may not be accepted by every Participating Dealer and may require an additional inspection.")),
            React.createElement("dt", { className: "indent" }, "E."),
            React.createElement("dd", null,
                React.createElement("p", null, "Some Offers Require Review. Although the majority of Instant Cash Offers generated by the Instant Cash Offer Program will be delivered within moments of submitting a valid request, some requests require manual review. This manual review process usually will be completed within minutes of submitting a request but occasionally may take until the next business day.")),
            React.createElement("dt", { className: "indent" }, "F."),
            React.createElement("dd", null,
                React.createElement("p", null, "The Information You Provide in Connection with Your Offer. You acknowledge that if you provide us with any false, incomplete or inaccurate information, including, without limitation, vehicle information or personal contact information, your Instant Cash Offer may be immediately invalidated. By requesting an Offer, you agree that we, our service providers, and Participating Dealers may contact you, by any method that you supplied for your contact information about vehicles or other products and services that may be of interest to you. By supplying a mobile number, you consent to receive calls or texts at that number, whether manually or automatically dialed, from us and from Participating Dealers. You do not have to provide us with a mobile number as a condition of obtaining an Instant Cash Offer.")),
            React.createElement("dt", { className: "indent" }, "G."),
            React.createElement("dd", null,
                React.createElement("p", null, "Participating Dealers")),
            React.createElement("dt", { className: "indent-2" }, "1."),
            React.createElement("dd", null,
                React.createElement("p", null,
                    "Participating Dealers pay a monthly subscription to participate in and receive leads through the Instant Cash Offer Program. Offers provided through the Instant Cash Offer Program are calculated and generated by a proprietary appraisal tool intended to generate a price reflecting a liquid cash offer to be honored by a Participating Dealer irrespective of whether you are a purchasing another vehicle from that Participating Dealer. Instant Cash Offer Program guarantees to Participating Dealers that your vehicle will be worth at least the amount of the Offer. Participating Dealers are not owned or operated by, nor are they acting on behalf of, Kelley Blue Book, and, except as described herein, are not otherwise affiliated with Kelley Blue Book. If you redeem your Instant Cash Offer at a Participating Dealer, you will have the option of either using your Instant Cash Offer to trade in your vehicle or to sell your car for a check. If your Instant Cash Offer does not identify a Participating Dealer near you, please call our customer service department at 1-866-545-4059 or send us an",
                    React.createElement("a", { href: "https://www.autotrader.com/help/email.jsp?contact_email=atc_info" }, "\u00A0email"),
                    " to request this information.")),
            React.createElement("dt", { className: "indent-2" }, "2."),
            React.createElement("dd", null,
                React.createElement("p", null, "Although Participating Dealers are not obligated to purchase your vehicle or accept your vehicle for trade for any price other than the amount of your Instant Cash Offer, you are welcome to try to negotiate a more favorable price or trade-in amount for your vehicle and, of course, you are always free to sell or trade-in your vehicle outside of the Instant Cash Offer Program. Though we think the Offer allows for a convenient and easy way to sell your vehicle, it may not be the highest sale price or trade-in amount that you could receive for your vehicle outside of the Instant Cash Offer Program.")),
            React.createElement("dt", { className: "indent" }, "H."),
            React.createElement("dd", null,
                React.createElement("p", null, "Verification Inspections")),
            React.createElement("dt", { className: "indent-2" }, "1."),
            React.createElement("dd", null,
                React.createElement("p", null, "Participating Dealers will conduct a mandatory vehicle inspection free of charge to verify the accuracy of the vehicle description and condition information that you provided to us online. This verification inspection may include, for example, paint thickness measurements, a road test, a mechanical inspection, and a review of vehicle history reports and disclosure statements regarding flood, salvage, or odometer discrepancies (including rollbacks), and the like. The Participating Dealer will submit the results of the verification inspection to the Instant Cash Offer Program using the same online tool that you used to request your Instant Cash Offer, and we may raise or lower the dollar amount of your Instant Cash Offer prior to redemption based on the information revealed by the inspection. All decisions of the Participating Dealer regarding the condition of your vehicle will be final.")),
            React.createElement("dt", { className: "indent-2" }, "2."),
            React.createElement("dd", null,
                React.createElement("p", null, "At the conclusion of your vehicle verification inspection, you can redeem your Instant Cash Offer, subject to any applicable adjustments after the inspection, by delivering your vehicle on the spot or by returning your vehicle to your Participating Dealer's location by the end of the next business day in the same condition and with no more than 50 additional miles on the odometer.")),
            React.createElement("dt", { className: "indent" }, "I."),
            React.createElement("dd", null,
                React.createElement("p", null, "Redeeming Your Instant Cash Offer")),
            React.createElement("dt", { className: "indent-2" }, "1."),
            React.createElement("dd", null,
                React.createElement("p", null, "Selling Your Vehicle to a Participating Dealer. If you decide to redeem your Instant Cash Offer by selling your vehicle, the Participating Dealer will issue you a check following the release of all applicable liens and lease obligations and the Participating Dealer's receipt of a clear and unencumbered vehicle title, subject to Section 3.H.3. All checks will be made payable to the vehicle owner(s) named on the vehicle title.")),
            React.createElement("dt", { className: "indent-2" }, "2."),
            React.createElement("dd", null,
                React.createElement("p", null, "Redeeming Your Offer Towards the Purchase of Another Vehicle. If you elect to apply your Instant Cash Offer to the purchase of another vehicle, your Participating Dealer will issue you a credit toward the price of any vehicle available in its inventory in exchange for your vehicle. The Offer credit will be given after the release of all applicable liens and lease obligations and the receipt of a clear and unencumbered vehicle title (see Section 3.H.3).")),
            React.createElement("dt", { className: "indent-2" }, "3."),
            React.createElement("dd", null,
                React.createElement("p", null, "Equity in Your Vehicle. If there are outstanding lien or lease obligations on your vehicle, the following will apply:")),
            React.createElement("dt", { className: "indent-3" }, "i."),
            React.createElement("dd", null,
                React.createElement("p", null, "Negative Equity. If you have negative equity in your vehicle (i.e., your outstanding loan balance or lease payment obligation exceeds the amount of your Instant Cash Offer), you will be responsible for paying the difference between the Instant Cash Offer and the amount needed to clear the title to your vehicle by certified check or other form of payment acceptable to the Participating Dealer before your transaction can be completed.")),
            React.createElement("dt", { className: "indent-3" }, "ii."),
            React.createElement("dd", null,
                React.createElement("p", null, "Positive Equity. If you have positive equity in your vehicle, the Participating Dealer will issue you a check or a trade-in credit, as applicable, for the difference between the amount needed to clear the title (including any related fees) to your vehicle and the Instant Cash Offer. Your Participating Dealer will assist you with the processing of the paperwork and payments necessary to settle your loan or lease obligations and to obtain a clear title to your vehicle. This process typically takes 10 to 14 business days following delivery of a vehicle to a Participating Dealer, but it could take longer in some circumstances.")),
            React.createElement("dt", { className: "indent" }, "J."),
            React.createElement("dd", null,
                React.createElement("p", null, "Release. BY REDEEMING AN OFFER, YOU AGREE NOT TO SUE KELLEY BLUE BOOK, ITS AFFILIATES, ITS SERVICE PROVIDERS, OR ITS OR THEIR RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS (COLLECTIVELY, THE \"RELEASED PARTIES\") FOR, AND AGREE TO RELEASE AND HOLD HARMLESS THE RELEASED PARTIES FROM AND AGAINST, ANY AND ALL CLAIMS RELATING TO OR ARISING OUT OF ANY VEHICLE SALE OR INSTANT CASH OFFER TRANSACTION THAT YOU ENTER INTO WITH A DEALER IN CONNECTION WITH THE INSTANT CASH OFFER PROGRAM. IN THE EVENT THAT ANY CLAIMS OR DISPUTES ARISE OUT OF SUCH A TRANSACTION, YOU AGREE TO LOOK SOLELY TO YOUR PARTICIPATING DEALER FOR YOUR REMEDY AND NOT TO THE RELEASED PARTIES.")),
            React.createElement("dt", null, "IV."),
            React.createElement("dd", null,
                React.createElement("h5", null, "Third-Party Services")),
            React.createElement("dt", { className: "indent" }, "A."),
            React.createElement("dd", null,
                React.createElement("p", null, "For your convenience, we make available a variety of links to other websites that we do not operate where you can obtain automotive-related products and services, including insurance, warranties, financing, and inspection services.")),
            React.createElement("dt", { className: "indent" }, "B."),
            React.createElement("dd", null,
                React.createElement("p", null, "If you choose to purchase an inspection through a third-party website for a vehicle that you are interested in buying, remember that an inspection is not a warranty. Although inspections can be of significant value in the purchase of a pre-owned vehicle, an inspection is not a guarantee that the inspected vehicle is free from defects or that the inspectors have identified all existing defects. Before you purchase inspection services, you should confirm with the inspector the location at which the inspection will be conducted. You are responsible for coordinating and arranging the inspection. You release Autotrader and its affiliates from any damages that you may incur, and agree not to assert any claims against them, arising from your purchase or use of third-party supplied products and services.")),
            React.createElement("dt", { className: "indent" }, "C."),
            React.createElement("dd", null,
                React.createElement("p", null, "Although we make available links to the websites of third-party providers of products and services, we are not responsible for the prices, terms, quality, reliability, or performance of the products or services provided by them. Your correspondence or business dealings with, or participation in the promotions of, vendors or other third parties found on or through the Autotrader Sites, including payment and delivery of goods or services and any other terms, conditions, warranties, or representations associated with such dealings, are solely between you and such third party. We are not responsible or liable for any loss or damage incurred as the result of such dealings with or the presence of such third parties on the Autotrader Sites.")),
            React.createElement("dt", { className: "indent" }, "D."),
            React.createElement("dd", null,
                React.createElement("p", null, "For your convenience, we make available links to websites operated by dealers that list their vehicles on the Autotrader Sites. Although we or our affiliates may power some of these dealer websites, we are not responsible for any of the content that appears on these websites, including, without limitation, promotional contests and sweepstakes offers. We do not co-sponsor, operate, endorse, or guarantee any advertiser sweepstakes or contest offer that may be promoted from time to time on the Autotrader Sites, or that may be accessible through a link from the Autotrader Sites, and you release Autotrader and our affiliates from any damages that you may incur, and agree not to assert any claims against any of them, arising from your entry, participation, or acceptance of any prize in any such sweepstakes or contest offer.")),
            React.createElement("dt", null, "V."),
            React.createElement("dd", null,
                React.createElement("h5", null, "Ownership and Permissions")),
            React.createElement("dt", { className: "indent" }, "A."),
            React.createElement("dd", null,
                React.createElement("p", null,
                    "The Autotrader Sites is provided to our end users for their personal, non-commercial use only. The materials on the Autotrader Sites are the property of Autotrader or its licensors, and are protected by U.S. copyright laws, other copyright laws, and international conventions. Except as explicitly provided in this Visitor Agreement, you may not distribute, transmit, display, reproduce, modify, create derivative works from, or otherwise exploit any of the materials on the Autotrader Sites. You may display and occasionally print a single copy of any page of the Autotrader Sites for your personal, non-commercial use, but you may not otherwise reproduce any material appearing on the Autotrader Sites without the prior written consent of the owner of such materials. You may not store any significant portion of, nor distribute copies of, materials found on the Autotrader Sites, in any form (including electronic form), without prior written permission from the owner of such materials. Requests for permission to reproduce or distribute materials found on the Autotrader Sites should be sent to our Customer Service Manager, Autotrader, 3003 Summit Boulevard, Suite 200, Atlanta, Georgia 30319 or to",
                    React.createElement("a", { href: "https://www.autotrader.com/help/email.jsp?contact_email=atc_info" }, "\u00A0Customer Service"),
                    ".")),
            React.createElement("dt", { className: "indent" }, "B."),
            React.createElement("dd", null,
                React.createElement("p", null,
                    "You are free to establish a hypertext link to the Autotrader Sites so long as the link does not state or imply any endorsement or sponsorship of you, your company, or your website by Autotrader or any of our affiliates. However, without our prior written permission, you may not frame any of the content of the Autotrader Sites, nor incorporate into another website or service any intellectual property of Autotrader or its licensors. Requests for permission to frame our content or use our content in any way that is not expressly described in this Visitor Agreement should be sent to our Customer Service Manager, either by",
                    React.createElement("a", { href: "https://www.autotrader.com/help/email.jsp?contact_email=atc_info" }, "\u00A0email"),
                    " or to the mailing address above.")),
            React.createElement("dt", { className: "indent" }, "C."),
            React.createElement("dd", null,
                React.createElement("p", null, "Autotrader and the Autotrader logo are all trademarks owned by us or our licensors. The names of other products and services referred to on the Autotrader Sites may be the trademarks of their respective owners. You may not use any trademark or service mark appearing on the Autotrader Sites without the prior written consent of the owner of the mark.")),
            React.createElement("dt", { className: "indent" }, "D."),
            React.createElement("dd", null,
                React.createElement("p", null, "You acknowledge that by transmitting or posting any material on or through the Autotrader Sites, you grant us, or anyone authorized by us, an unrestricted, non-exclusive, worldwide, royalty-free, perpetual, irrevocable, license to use, modify, perform, display, broadcast, reproduce, create derivative works from, transmit, sell or otherwise use, exploit or distribute, at no cost whatsoever, all such material (including, without limitation, all intellectual property rights embodied therein), in whole or in part, in any manner or medium (whether now known or hereafter developed), for any purpose. The foregoing license includes the right to exploit any proprietary rights in such material, including, but not limited to, rights under copyright, trademark, or patent laws that exist in any applicable jurisdiction. Also, in connection with the exercise of these rights, you grant us and anyone authorized by us, the right to identify you as the author of such material by name, email address or user name, and to use your image and likeness if provided, in connection with such material. You will not receive any compensation of any kind for the use of any material you transmit or post via the Autotrader Sites. Among other uses of user materials, the Autotrader Sites uses listings data to derive useful information (such as vehicle valuation information) for display, sale and distribution.")),
            React.createElement("dt", null, "VI."),
            React.createElement("dd", null,
                React.createElement("h5", null, "User Content")),
            React.createElement("dt", { className: "indent" }, "A."),
            React.createElement("dd", null,
                React.createElement("p", null, "The Autotrader Sites allows users to publish listings, ratings, and reviews and to access social media tools where users may post material accessible to others or transmit communications to others. Some of these tools may be operated by third parties, and your use of them is subject to both the terms of this Visitor Agreement and to the policies of their third-party providers.")),
            React.createElement("dt", { className: "indent" }, "B."),
            React.createElement("dd", null,
                React.createElement("p", null, "You assume total responsibility and risk for your use of any interactive areas of the Autotrader Sites. You acknowledge that any of the user-generated content posted or transmitted through the Autotrader Sites represents the views of the author, and not of Autotrader. You also acknowledge that your use of or reliance on such content is at your own risk.")),
            React.createElement("dt", { className: "indent" }, "C."),
            React.createElement("dd", null,
                React.createElement("p", null, "When publishing anything to the Autotrader Sites or using any social media tools or interactive features, you agree that you will not post or transmit:")),
            React.createElement("dt", { className: "indent-2" }, "1."),
            React.createElement("dd", null,
                React.createElement("p", null, "any copyrighted material unless you own or control the copyright in and to such material;")),
            React.createElement("dt", { className: "indent-2" }, "2."),
            React.createElement("dd", null,
                React.createElement("p", null, "material that is: knowingly false and/or defamatory, inaccurate, libelous, tortuous, abusive, vulgar, hateful, racist, bigoted, sexist, harassing, threatening, inflammatory, obscene, profane, sexually oriented, invasive of a person's privacy, or is otherwise objectionable or in violation of any applicable law, rule, or regulation;")),
            React.createElement("dt", { className: "indent-2" }, "3."),
            React.createElement("dd", null,
                React.createElement("p", null, "material that violates or infringes the rights of any other party, including, without limitation, rights of privacy, rights of publicity, copyright, trademark, or other intellectual property rights;")),
            React.createElement("dt", { className: "indent-2" }, "4."),
            React.createElement("dd", null,
                React.createElement("p", null, "profanity in subject lines, messages, or signatures;")),
            React.createElement("dt", { className: "indent-2" }, "5."),
            React.createElement("dd", null,
                React.createElement("p", null, "any material containing viruses, Trojan horses, worms, or any other disruptive or harmful component;")),
            React.createElement("dt", { className: "indent-2" }, "6."),
            React.createElement("dd", null,
                React.createElement("p", null, "material that breaches another's privacy, i.e., containing phone numbers, addresses, or other personal information;")),
            React.createElement("dt", { className: "indent-2" }, "7."),
            React.createElement("dd", null,
                React.createElement("p", null, "spam, including, but not limited to, junk mail, chain letters, unsolicited bulk email or duplicative messages, excessive cross-postings, and material that is unrelated to the forum in which it is posted;")),
            React.createElement("dt", { className: "indent-2" }, "8."),
            React.createElement("dd", null,
                React.createElement("p", null, "material that contains advertisements or commercial solicitations; or")),
            React.createElement("dt", { className: "indent-2" }, "9."),
            React.createElement("dd", null,
                React.createElement("p", null, "material discussing illegal activities or linking to websites that deal with such activities.")),
            React.createElement("dt", { className: "indent" }, "D."),
            React.createElement("dd", null,
                React.createElement("p", null, "You further agree that you will not attempt or do any of the following:")),
            React.createElement("dt", { className: "indent-2" }, "1."),
            React.createElement("dd", null,
                React.createElement("p", null, "interfere with or disrupt the Autotrader Sites or our computer systems, servers, or networks;")),
            React.createElement("dt", { className: "indent-2" }, "2."),
            React.createElement("dd", null,
                React.createElement("p", null, "attempt to gain unauthorized access to any part of the Autotrader Sites, to accounts that belong to other users, or to computer systems or networks connected to the Autotrader Sites;")),
            React.createElement("dt", { className: "indent-2" }, "3."),
            React.createElement("dd", null,
                React.createElement("p", null,
                    "engage in any systematic extraction of data or data fields, including, without limitation, email addresses, by use of any automated mechanism, such web robots, crawlers, or spiders (except in strict conformance with the",
                    React.createElement("a", { href: "https://www.autotrader.com/robots.txt" }, "\u00A0Robots Exclusion Protocol"),
                    ") or otherwise;")),
            React.createElement("dt", { className: "indent-2" }, "4."),
            React.createElement("dd", null,
                React.createElement("p", null, "collect information about others without their consent;")),
            React.createElement("dt", { className: "indent-2" }, "5."),
            React.createElement("dd", null,
                React.createElement("p", null, "interfere with the use of the Autotrader Sites by any other individual or party;")),
            React.createElement("dt", { className: "indent-2" }, "6."),
            React.createElement("dd", null,
                React.createElement("p", null, "impersonate any person, or otherwise attempt to mislead others about your identity, or post material under secondary user names or other aliases; or")),
            React.createElement("dt", { className: "indent-2" }, "7."),
            React.createElement("dd", null,
                React.createElement("p", null, "share any user name and/or password you have on the Autotrader Sites with any other persons.")),
            React.createElement("dt", { className: "indent" }, "E."),
            React.createElement("dd", null,
                React.createElement("p", null, "Without limiting any of Autotrader's other rights or remedies, a violation of any of the above may result in the removal of any content you have transmitted or posted, revocation of any accounts you have on the Autotrader Sites or on our affiliated websites and services, and/or a ban from creating new accounts.")),
            React.createElement("dt", { className: "indent" }, "F."),
            React.createElement("dd", null,
                React.createElement("p", null, "Although Autotrader cannot monitor all of the listings and content posted to the Autotrader Sites, we reserve the right (but assume no obligation) to delete, move, condense or edit any ads, ratings, reviews, content or other postings that come to our attention that we consider unacceptable or inappropriate, whether for legal or other reasons. We retain the right to deny access to anyone who we believe has violated these terms or any other term of this Visitor Agreement. We will not, in the ordinary course of business, review the content of private electronic messages that are not addressed to us. However, we may occasionally monitor such communications as we believe is appropriate to comply with applicable laws, respond to legal process or a law enforcement request, to enforce this Visitor Agreement, or to protect the rights, property or safety of visitors to the Autotrader Sites, our advertisers, the public, us or our affiliates. Notwithstanding the foregoing, Autotrader takes no responsibility and assumes no liability for any content posted to the Autotrader Sites by you or by third parties.")),
            React.createElement("dt", null, "VII."),
            React.createElement("dd", null,
                React.createElement("h5", null, "Termination"),
                React.createElement("p", null, "You agree that we may, under some circumstances and without prior notice to you, terminate your use of and access to any of the parts of the Autotrader Sites to which we restrict access, for example, by requiring registration. Some of the reasons for such termination may include, but are not limited to, (a) a breach or violation or suspected breach or violation of this Visitor Agreement or other incorporated terms or guidelines, (b) a request by law enforcement or another government agency, (c) our decision to discontinue or change all or part of the Autotrader Sites, (d) technical or security issues, and (e) fraudulent or illegal activities. All terminations will be made in our sole discretion and you agree that we will not be liable for any termination of your use of or access to the Autotrader Sites or any part of the Autotrader Sites.")),
            React.createElement("dt", null, "VIII."),
            React.createElement("dd", null,
                React.createElement("h5", null, "DISPUTE RESOLUTION \u2013 MANDATORY ARBITRATION AND CLASS ACTION WAIVER")),
            React.createElement("dt", { className: "indent" }, "A."),
            React.createElement("dd", null,
                React.createElement("p", null, "ARBITRATION AGREEMENT. YOU AND AUTOTRADER AGREE THAT ANY CLAIMS OR DISPUTES (\u201CClaims\u201D) THAT ARISE OUT OF OR RELATE IN ANY WAY TO THE TERMS OF THE VISITOR AGREEMENT, THE AUTOTRADER SITES, OR ANY SERVICE (INCLUDING BUT NOT LIMITED TO BILLING DISPUTES) SHALL BE RESOLVED BY FINAL AND BINDING ARBITRATION OR IN SMALL CLAIMS COURT. In arbitration, there is no judge and no jury. Instead, Claims are decided by an arbitrator whose authority is created by and governed by this arbitration agreement. Review of arbitration awards in the courts is very limited.")),
            React.createElement("dt", { className: "indent" }, "B."),
            React.createElement("dd", null,
                React.createElement("p", null, "CLASS ACTION WAIVER: YOU AND AUTOTRADER AGREE THAT ALL CLAIMS BETWEEN US WILL BE RESOLVED IN AN INDIVIDUAL ARBITRATION. WE BOTH AGREE THAT THERE WILL BE NO CLASS, REPRESENTATIVE, OR CONSOLIDATED ACTIONS IN ARBITRATION. In addition, neither you nor Autotrader may participate in a class or representative action in court as a class member if the claims asserted in the litigation would fall within the scope of this arbitration agreement if asserted directly by you or Autotrader. To be clear, you and Autotrader both waive any right to participate in any class action involving disputes between us.")),
            React.createElement("dt", { className: "indent" }, "C."),
            React.createElement("dd", null,
                React.createElement("p", null, "This class action waiver is an essential part of our arbitration agreement and may not be severed. If for any reason this class action waiver is found unenforceable, then the entire arbitration agreement will not apply. However, the Jury Trial Waiver set forth in Section 9 of the Visitor Agreement will remain in full force and effect.")),
            React.createElement("dt", { className: "indent" }, "D."),
            React.createElement("dd", null,
                React.createElement("p", null, "ARBITRATOR AUTHORITY: The arbitrator's authority is governed by this arbitration agreement. You and Autotrader agree that the arbitrator may award the same relief that a court of competent jurisdiction could award \u2013 consistent with and limited by the Visitor Agreement (including the limitations of liability set forth in Section 10), but the arbitrator may not award declaratory or injunctive relief that extends beyond you and your dealings with Autotrader. An arbitrator may award attorneys\u2019 fees and costs to the prevailing party if a court would be authorized to do so under the applicable law.")),
            React.createElement("dt", { className: "indent" }, "E."),
            React.createElement("dd", null,
                React.createElement("p", null,
                    "ARBITRATION PROCEDURES: You and Autotrader agree that your agreement affects interstate commerce, and the Federal Arbitration Act applies. All arbitrations shall be conducted by JAMS Endispute under its Streamlined Arbitration Rules and Procedures (\u201CJAMS\u201D). These Rules are available on the JAMS website at",
                    React.createElement("a", { href: "http://www.jamsadr.com/" }, "\u00A0http://www.jamsadr.com"),
                    " or by calling 1.800.352.5267. If there is a conflict between the JAMS Rules and this arbitration agreement, then this arbitration agreement shall control. Autotrader will pay all filing fees and costs associated with commencing an arbitration, but you will be responsible for paying your own attorneys\u2019 fees (if you chose to use an attorney in arbitration) unless you prevail in the arbitration and the arbitrator finds that you are entitled to recover your fees under the law. Except for claims determined to be frivolous, Autotrader agrees not to seek an award of attorneys\u2019 fees in arbitration even if an award is otherwise available under applicable law. If you bring an arbitration against Autotrader, the arbitration hearing will be held in your hometown area, unless we both agree on a different location.")),
            React.createElement("dt", { className: "indent" }, "F."),
            React.createElement("dd", null,
                React.createElement("p", null, "SURVIVAL: This arbitration agreement survives the termination of this Visitor Agreement between you and Autotrader.")),
            React.createElement("dt", null, "IX."),
            React.createElement("dd", null,
                React.createElement("h5", null, "JURY TRIAL WAIVER"),
                React.createElement("p", null, "You and Autotrader expressly and knowingly WAIVE THE RIGHT TO TRIAL BY JURY. This means that if for any reason the arbitration agreement contained in Section 8 is not enforced or is found inapplicable, our claims against each other will be resolved by a judge rather than a jury.")),
            React.createElement("dt", null, "X."),
            React.createElement("dd", null,
                React.createElement("h5", null, "Disclaimer of Warranties, Limitation of Liability, and Indemnification")),
            React.createElement("dt", { className: "indent" }, "A."),
            React.createElement("dd", null,
                React.createElement("p", null, "IF YOU RELY ON THE AUTOTRADER SITES OR ANY INFORMATION, PRODUCT, OR SERVICE AVAILABLE THROUGH THE AUTOTRADER SITES, YOU DO SO AT YOUR OWN RISK. YOU UNDERSTAND THAT THERE MAY BE DELAYS, OMISSIONS, INTERRUPTIONS, INACCURACIES, AND/OR OTHER PROBLEMS WITH THE INFORMATION, PRODUCTS, AND SERVICES PUBLISHED ON OR PROMOTED THROUGH THE AUTOTRADER SITES. THE AUTOTRADER SITES ARE PROVIDED TO YOU \"AS IS.\" AUTOTRADER AND ITS AFFILIATES, AGENTS, AND LICENSORS CANNOT AND DO NOT WARRANT THE ACCURACY, COMPLETENESS, CURRENTNESS, NONINFRINGEMENT, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE OF THE INFORMATION AVAILABLE THROUGH THE AUTOTRADER SITES (OR ANY INFORMATION, GOODS, OR SERVICES THAT ARE REFERRED TO, ADVERTISED OR PROMOTED ON, OR SOLD THROUGH THE AUTOTRADER SITES). NOR DO WE OR THEY GUARANTEE THAT THE AUTOTRADER SITES WILL BE ERROR FREE, OR CONTINUOUSLY AVAILABLE, OR THAT THE AUTOTRADER SITES WILL BE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. AUTOTRADER AND ITS AFFILIATES, AGENTS, AND LICENSORS WILL NOT BE LIABLE FOR ANY CLAIMS, ACTIONS, OR JUDGMENTS ARISING OUT OF OR RELATED TO ANY CONTENT POSTED TO THE AUTOTRADER SITES BY YOU OR ANY THIRD PARTY.")),
            React.createElement("dt", { className: "indent" }, "B."),
            React.createElement("dd", null,
                React.createElement("p", null, "UNDER NO CIRCUMSTANCES WILL AUTOTRADER OR ITS AFFILIATES, AGENTS, OR LICENSORS BE LIABLE TO YOU OR ANYONE ELSE FOR ANY DAMAGES ARISING OUT OF YOUR USE OF THE AUTOTRADER SITES OR ANY PRODUCT OR SERVICE LINKED TO OR FROM OR ADVERTISED OR PROMOTED ON THE AUTOTRADER SITES, INCLUDING, WITHOUT LIMITATION, CONSEQUENTIAL, SPECIAL, INCIDENTAL, INDIRECT, PUNITIVE, EXEMPLARY, OR OTHER DAMAGES OF ANY KIND (INCLUDING LOST REVENUES OR PROFITS, LOSS OF BUSINESS, AND LOSS OF DATA), EVEN IF WE ARE ADVISED BEFOREHAND OF THE POSSIBILITY OF SUCH DAMAGES. YOU AGREE THAT THE LIABILITY OF AUTOTRADER AND ITS AFFILIATES, AGENTS, AND LICENSORS, IF ANY, ARISING OUT OF ANY KIND OF LEGAL CLAIM ARISING OUT OF OR OTHERWISE RELATING TO THE AUTOTRADER SITES WILL NOT EXCEED THE AMOUNT YOU PAID, IF ANY, FOR THE USE OF THE AUTOTRADER SITES OUT OF WHICH SUCH LIABILITY ALLEGEDLY ARISES. WITHOUT LIMITING THE GENERALITY OF THE PRECEDING SENTENCE, YOU AGREE THAT WE ARE NOT RESPONSIBLE OR LIABLE TO YOU OR ANYONE ELSE FOR ANY THREATENING, DEFAMATORY, OBSCENE, OFFENSIVE, TORTIOUS, OR ILLEGAL CONDUCT BY YOU OR ANY OTHER PARTY OR ANY INFRINGEMENT OF YOUR OR ANOTHER'S RIGHTS ARISING FROM OR IN CONNECTION WITH THE AUTOTRADER SITES.")),
            React.createElement("dt", { className: "indent" }, "C."),
            React.createElement("dd", null,
                React.createElement("p", null, "SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF THE ABOVE DISCLAIMERS AND LIMITATIONS OF LIABILITY MAY NOT APPLY.")),
            React.createElement("dt", { className: "indent" }, "D."),
            React.createElement("dd", null,
                React.createElement("p", null, "YOU AGREE TO INDEMNIFY AUTOTRADER AND ITS OWNERS, SHAREHOLDERS, SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, PARTNERS, AND LICENSORS, AND HOLD THEM HARMLESS FROM ANY AND ALL CLAIMS AND EXPENSES, INCLUDING ATTORNEY'S FEES, ARISING FROM OR RELATED IN ANY WAY TO YOUR USE OF THE AUTOTRADER SITES OR ANY BREACH OF THIS VISITOR AGREEMENT.")),
            React.createElement("dt", null, "XI."),
            React.createElement("dd", null,
                React.createElement("h5", null, "Registration")),
            React.createElement("dt", { className: "indent" }, "A."),
            React.createElement("dd", null,
                React.createElement("p", null,
                    "To obtain access to certain services from the Autotrader Sites (for instance, to manage your private seller listing on the Autotrader Sites), you may be required to register on the Autotrader Sites. As part of the registration process, you will be required to select a user name and a password. You agree that the information you supply during that registration process will be accurate and complete, and that you will not register under the name of another person. Failure to provide accurate and timely information may result in your account being closed and/or your access to content provided through your account being suspended, discontinued, or removed. We reserve the right to disallow use of a user name that we deem offensive or inappropriate. You will be responsible for preserving the confidentiality of your password and for all actions of persons accessing the Autotrader Sites through any username/password assigned to you. You will notify Autotrader's Customer Service Manager of any known or suspected unauthorized use of your account via",
                    React.createElement("a", { href: "https://www.autotrader.com/help/email.jsp?contact_email=atc_info" }, "\u00A0email"),
                    ".")),
            React.createElement("dt", { className: "indent" }, "B."),
            React.createElement("dd", null,
                React.createElement("p", null,
                    "Autotrader reserves the right to disclose any information we collect through user accounts and registrations in accordance with our",
                    React.createElement("a", { href: "https://www.autotrader.com/legal/privacy-statement.xhtml" }, "\u00A0Privacy Statement"),
                    ".")),
            React.createElement("dt", null, "XII."),
            React.createElement("dd", null,
                React.createElement("h5", null, "Notice of Copyright Infringement"),
                React.createElement("p", null, "If you are an owner of intellectual property who believes your intellectual property has been improperly posted or distributed via the Autotrader Sites, please notify us by sending an email to dmca@autotrader.com or by sending a notice by U.S. Mail to: Autotrader, 3003 Summit Boulevard, Suite 200, Atlanta, Georgia 30319, Attn: Legal Department/Copyright. Your notice to us must include the following information: (1) a physical or electronic signature of a person authorized to act on behalf of the owner of the copyrighted work allegedly infringed; (2) a description of the copyrighted work or works that allegedly have been infringed; (3) a description of where on the Autotrader Sites the allegedly infringing material appears that will allow us to locate the material; (4) your contact information, including your address, telephone number, and, if available, email address; (5) a statement by you that you have a good faith belief that the allegedly infringing use has not been authorized by the copyright owner, its agent, or the law; and (6) a statement by you, under penalty of perjury, that the information in your notice is accurate and that you are authorized to act on behalf of the owner of the copyrighted work that has allegedly been infringed.")),
            React.createElement("dt", null, "XIII."),
            React.createElement("dd", null,
                React.createElement("h5", null, "Miscellaneous")),
            React.createElement("dt", { className: "indent" }, "A."),
            React.createElement("dd", null,
                React.createElement("p", null, "We want potential buyers visiting the Autotrader Sites to feel free to share their email addresses with those listing vehicles for sale on the Autotrader Sites. By accessing the Autotrader Sites, you agree not to use information concerning other users of the Autotrader Sites, or the vehicles they have listed or searched for on the Autotrader Sites (including listing information, user names, email addresses, telephone numbers, and/or other information), for any purpose other than to explore the potential purchase or sale of a listed vehicle.")),
            React.createElement("dt", { className: "indent" }, "B."),
            React.createElement("dd", null,
                React.createElement("p", null, "This Visitor Agreement has been made in, and will be construed in accordance with the laws of, the State of Georgia, without regard to its choice of laws rules.")),
            React.createElement("dt", { className: "indent" }, "C."),
            React.createElement("dd", null,
                React.createElement("p", null,
                    "Please report any violations of this Visitor Agreement to our",
                    React.createElement("a", { href: "https://www.autotrader.com/help/email.jsp?contact_email=atc_info" }, "\u00A0Customer Service Manager"),
                    "."))),
        React.createElement("h3", null, "END OF VISITOR AGREEMENT")));
};
