import { connect } from 'react-redux';
import CashSummaryUI from './CashSummaryUI';
import { selectors } from '@makemydeal/dr-offer-redux';
import { isValidDollarAmount, formatDollars } from '../../utils/formatUtils';
import { getVehicleRetailPrice } from './selectors';
export var mapStateToProps = function (state, ownProps) {
    var offer = selectors.getOfferDetails(state, 'cash');
    var vehicle = selectors.getVehicleSinglePriceData(state).vehicle;
    var vehicleCondition = vehicle.condition;
    var dealerFees = offer.dealerFees;
    var dealerFeesTotal = offer.dealerFeesTotal;
    var special = null;
    if (offer.totalIncentives > 0) {
        special = formatDollars(offer.totalIncentives);
    }
    var initialOfferPrice = offer.initialOfferPrice;
    var totalTax = offer.totalTax > 0 ? offer.totalTax : null;
    var dmvFees = offer.dmvFees;
    var tradeValue = selectors.getTradeInValue(state);
    var tradeAmtOwed = selectors.getTradeInAmountOwed(state);
    var netTradeIn = isNaN(tradeValue - tradeAmtOwed) ? null : tradeValue - tradeAmtOwed;
    var offerPrice = isValidDollarAmount(offer.offerPrice) ? offer.offerPrice : null;
    var absoluteTradeIn = netTradeIn > 0 ? netTradeIn : -1 * netTradeIn;
    var formattedNetTradeIn = isValidDollarAmount(absoluteTradeIn) ? formatDollars(absoluteTradeIn) : null;
    var tradeInWithSign = netTradeIn > 0 ? "-" + formattedNetTradeIn : formattedNetTradeIn;
    var isSinglePrice = selectors.isVehicleSinglePrice(state);
    var cashOffer = selectors.getCashOfferRoundedUp(state);
    var retailPrice = getVehicleRetailPrice(state);
    return {
        isSinglePrice: isSinglePrice,
        tradeValue: tradeValue,
        tradeInWithSign: tradeInWithSign,
        vehicle: vehicle,
        cashOffer: cashOffer,
        offerPrice: offerPrice,
        vehicleCondition: vehicleCondition,
        dealerFees: dealerFees,
        dealerFeesTotal: dealerFeesTotal,
        initialOfferPrice: initialOfferPrice,
        totalTax: totalTax,
        dmvFees: dmvFees,
        retailPrice: retailPrice,
        special: special,
        advertisedPriceLabel: (ownProps && ownProps.advertisedPriceLabel) || null
    };
};
var mapDispatchToProps = function (dispatch) {
    return {};
};
var CashSummary = connect(mapStateToProps, mapDispatchToProps)(CashSummaryUI);
export default CashSummary;
