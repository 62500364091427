import { connect } from 'react-redux';
import HeaderUI from './HeaderUI';
import { routingActionCreator } from '../ActionCard/actions/routingActionCreators';
import * as Types from '../ActionCard/actions/actionTypes';
var mapStateToProps = function (state) {
    var headerContext = state.headerContext;
    return { headerContext: headerContext };
};
var mapDispatchToProps = function (dispatch) {
    return {
        handleBack: function (alternativeRoute) {
            dispatch(routingActionCreator(Types.ROUTE_BACK, alternativeRoute));
        },
        handleHome: function () {
            dispatch(routingActionCreator(Types.ROUTE_HOME));
        }
    };
};
var HeaderContainer = connect(mapStateToProps, mapDispatchToProps)(HeaderUI);
export default HeaderContainer;
