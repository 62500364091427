var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Component } from 'react';
import * as PropTypes from 'prop-types';
var ActionCardUI = /** @class */ (function (_super) {
    __extends(ActionCardUI, _super);
    function ActionCardUI(props) {
        return _super.call(this, props) || this;
    }
    ActionCardUI.prototype.isClickable = function () {
        var routeTo = this.props.routeTo;
        return routeTo !== null && routeTo !== undefined;
    };
    ActionCardUI.prototype.handleClick = function () {
        var _a = this.props, onCardClick = _a.onCardClick, routeTo = _a.routeTo, cardState = _a.cardState, disable = _a.disable, handlerPostRoute = _a.handlerPostRoute;
        if (!disable && this.isClickable()) {
            onCardClick(routeTo);
        }
        handlerPostRoute && handlerPostRoute();
    };
    ActionCardUI.prototype.getLeftIcon = function (showLeftIcon, iconType) {
        if (!showLeftIcon) {
            return null;
        }
        else {
            return (React.createElement("div", { className: "left-icon-container" },
                React.createElement("div", { className: "left-icon " + iconType })));
        }
    };
    ActionCardUI.prototype.getRightIcon = function (showRightIcon) {
        if (!showRightIcon) {
            return null;
        }
        else {
            return (React.createElement("div", { className: "right-icon-container" },
                React.createElement("div", { className: "right-icon" })));
        }
    };
    ActionCardUI.prototype.render = function () {
        var _a = this.props, cardState = _a.cardState, showLeftIcon = _a.showLeftIcon, showRightIcon = _a.showRightIcon, cardHeading = _a.cardHeading, secondaryText = _a.secondaryText, iconType = _a.iconType, disable = _a.disable, routeTo = _a.routeTo, onCardClick = _a.onCardClick;
        var leftIcon = this.getLeftIcon(showLeftIcon, iconType);
        var rightIcon = this.getRightIcon(showRightIcon);
        var className = "dr-ui-actioncard" + (this.isClickable() ? '' : ' dr-ui-leaf');
        return (React.createElement("div", { id: this.props.id, className: className, onClick: this.handleClick.bind(this) },
            React.createElement("div", { className: "" + cardState + (disable ? ' disabled' : '') },
                leftIcon,
                React.createElement("div", { className: "card-text" },
                    React.createElement("div", { className: "header" }, cardHeading),
                    React.createElement("div", { className: "secondary" }, secondaryText)),
                rightIcon)));
    };
    ActionCardUI.propTypes = {
        id: PropTypes.string,
        cardState: PropTypes.string,
        showLeftIcon: PropTypes.bool,
        showRightIcon: PropTypes.bool,
        cardHeading: PropTypes.string.isRequired,
        secondaryText: PropTypes.string,
        iconType: PropTypes.string,
        routeTo: PropTypes.string,
        disable: PropTypes.bool,
        isClickable: PropTypes.bool
    };
    ActionCardUI.defaultProps = {
        showLeftIcon: true,
        showRightIcon: true,
        cardHeading: 'Card Heading Text',
        iconType: 'offer',
        cardState: 'active'
    };
    return ActionCardUI;
}(Component));
export default ActionCardUI;
