var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Component } from 'react';
import * as PropTypes from 'prop-types';
var propTypes = {
    installment: PropTypes.string
};
var MonthlyInstallmentUI = /** @class */ (function (_super) {
    __extends(MonthlyInstallmentUI, _super);
    function MonthlyInstallmentUI(props) {
        return _super.call(this, props) || this;
    }
    MonthlyInstallmentUI.prototype.render = function () {
        var installment = this.props.installment;
        return (React.createElement("div", { className: "dr-ui-monthly-payment text-center" },
            React.createElement("span", { className: "value" }, installment),
            React.createElement("span", { className: "postfix" }, "/mo")));
    };
    MonthlyInstallmentUI.defaultProps = {
        installment: '$--'
    };
    return MonthlyInstallmentUI;
}(Component));
export default MonthlyInstallmentUI;
