export var formatSimpleMarkdownAsHtml = function (text) {
    if (!text) {
        return text;
    }
    var parts = text.split('**');
    if (parts.length === 1) {
        return text;
    }
    var result = '';
    var openingBoldTag = true;
    for (var idx = 0; idx <= parts.length - 1; idx++) {
        var part = parts[idx];
        result += part;
        if (idx < parts.length - 1) {
            if (openingBoldTag) {
                result += '<b>';
            }
            else {
                result += '</b>';
            }
        }
        openingBoldTag = !openingBoldTag;
    }
    return result;
};
