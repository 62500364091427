import { connect } from 'react-redux';
import ButtonCardUI from './ButtonCardUI';
import { routingActionCreator } from './actions/routingActionCreators';
var mapStateToProps = function (state) {
    return {};
};
var mapDispatchToProps = function (dispatch) {
    return {
        onButtonClick: function (route) { return dispatch(routingActionCreator(route)); }
    };
};
var ButtonCardContainer = connect(mapStateToProps, mapDispatchToProps)(ButtonCardUI);
export default ButtonCardContainer;
