import { connect } from 'react-redux';
import InfoIconUI from './InfoIconUI';
import { routingActionCreator } from './actions/routingActionCreators';
var mapStateToProps = function (state) {
    var pathname = state.routing.locationBeforeTransitions.pathname;
    return {
        pathname: pathname
    };
};
var mapDispatchToProps = function (dispatch) {
    return {
        onIconClick: function (route) { return dispatch(routingActionCreator(route)); }
    };
};
var InfoIconContainer = connect(mapStateToProps, mapDispatchToProps)(InfoIconUI);
export default InfoIconContainer;
