export var isLeadFormOpen = function (state) {
    if (state && state.leadForm && state.leadForm.isLeadFormOpen) {
        return true;
    }
    return false;
};
export var getVehicle = function (state) { return state.offer.vehicle; };
export var getVehicleRetailPrice = function (state) { return getVehicle(state).retailPrice; };
export function getIsDueAtSigninAmountDeduct(state) {
    var leaseSettings = state.dealer.leaseSettings;
    // lease cap cost due at signing flag
    // default value needs to be set to true in case this hasn't been implemented on the dealer.
    // False is a valid value though, so we only need to check for undefined or null in order to default.
    return (leaseSettings
        && leaseSettings.isDueAtSigninAmountDeduct !== undefined
        && leaseSettings.isDueAtSigninAmountDeduct !== null)
        ? leaseSettings.isDueAtSigninAmountDeduct : true;
}
