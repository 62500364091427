var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Component } from 'react';
import { Manager, Reference, Popper } from 'react-popper';
var TooltipUI = /** @class */ (function (_super) {
    __extends(TooltipUI, _super);
    function TooltipUI(props) {
        var _this = _super.call(this, props) || this;
        _this.handleClick = function (e) {
            if (_this.tooltipRef) {
                if (_this.tooltipRef.contains(e.target)) {
                    return;
                }
                _this.hideTooltip();
            }
        };
        _this.hideTooltip = function () {
            _this.setState({ showTooltip: false });
        };
        _this.toggleShowToolTipMessage = function () {
            _this.setState({ showTooltip: !_this.state.showTooltip });
        };
        _this.state = {
            showTooltip: false
        };
        return _this;
    }
    TooltipUI.prototype.componentWillMount = function () {
        document.addEventListener('mousedown', this.handleClick, false);
    };
    TooltipUI.prototype.componentWillUnmount = function () {
        document.removeEventListener('mousedown', this.handleClick, false);
    };
    TooltipUI.prototype.render = function () {
        var _this = this;
        return (React.createElement(Manager, null,
            React.createElement(Reference, null, function (_a) {
                var ref = _a.ref;
                var clonedBaseElement = React.cloneElement(_this.props.baseElement, {
                    ref: ref,
                    onClick: _this.toggleShowToolTipMessage
                });
                return clonedBaseElement;
            }),
            this.state.showTooltip ?
                React.createElement(Popper, { placement: this.props.placement }, function (_a) {
                    var ref = _a.ref, style = _a.style, placement = _a.placement, arrowProps = _a.arrowProps;
                    return (React.createElement("div", { className: "tool-tip-container", ref: ref, style: style, "data-placement": placement },
                        React.createElement("div", { className: "tool-tip-message", ref: function (node) { return _this.tooltipRef = node; } },
                            _this.props.tooltipContent,
                            React.createElement("div", { className: "message-link-container" },
                                React.createElement("a", { onClick: _this.hideTooltip, className: "message-link" }, "Close"))),
                        React.createElement("div", { ref: arrowProps.ref, style: arrowProps.style })));
                })
                : null));
    };
    return TooltipUI;
}(Component));
export default TooltipUI;
