var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Component } from 'react';
// tslint:disable-next-line:import-name
import YouTube from 'react-youtube';
import HowItWorksContentUI from './HowItWorksContentUI';
var HowItWorksUI = /** @class */ (function (_super) {
    __extends(HowItWorksUI, _super);
    function HowItWorksUI(props) {
        return _super.call(this, props) || this;
    }
    HowItWorksUI.prototype.render = function () {
        var _a = this.props, dealerVideoId = _a.dealerVideoId, videoOpts = _a.videoOpts;
        return (React.createElement("div", { className: "how-it-works-content-container" },
            React.createElement("div", { className: "how-it-works-video-container" },
                React.createElement(YouTube, { videoId: dealerVideoId, opts: videoOpts })),
            React.createElement("div", { className: "how-it-works-content" },
                React.createElement(HowItWorksContentUI, { cardHeading: "Customize Your Payments", secondaryText: "Get a realistic payment based on your info.", iconType: "offer" }),
                React.createElement(HowItWorksContentUI, { cardHeading: "Value Your Trade-In", secondaryText: "See what Kelley Blue Book says your car is worth.", iconType: "how-it-works-trade-icon" }),
                React.createElement(HowItWorksContentUI, { cardHeading: "Apply for Financing", secondaryText: "Choose from options that meet your needs. ", iconType: "credit" }),
                React.createElement(HowItWorksContentUI, { cardHeading: "Schedule a Test Drive", secondaryText: "Have a car waiting when you arrive.", iconType: "schedule" }),
                React.createElement(HowItWorksContentUI, { cardHeading: "Enjoy your new ride!", secondaryText: "", iconType: "how-it-works-enjoy-icon", isFinal: true }))));
    };
    return HowItWorksUI;
}(Component));
export default HowItWorksUI;
