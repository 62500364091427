var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Component } from 'react';
import * as PropTypes from 'prop-types';
var ButtonCardUI = /** @class */ (function (_super) {
    __extends(ButtonCardUI, _super);
    function ButtonCardUI(props) {
        return _super.call(this, props) || this;
    }
    ButtonCardUI.prototype.isClickable = function () {
        var routeTo = this.props.routeTo;
        return routeTo !== null && routeTo !== undefined;
    };
    ButtonCardUI.prototype.handleClick = function () {
        var _a = this.props, onButtonClick = _a.onButtonClick, routeTo = _a.routeTo, buttonState = _a.buttonState, disable = _a.disable, handlerPostRoute = _a.handlerPostRoute;
        if (!disable && this.isClickable()) {
            onButtonClick(routeTo);
        }
        handlerPostRoute && handlerPostRoute();
    };
    ButtonCardUI.prototype.render = function () {
        var _this = this;
        var _a = this.props, buttonState = _a.buttonState, buttonText = _a.buttonText, disable = _a.disable, routeTo = _a.routeTo, onButtonClick = _a.onButtonClick;
        return (React.createElement("button", { type: "button", disabled: disable, className: "btn btn-primary btn-sm btn-block dr-ui-buttoncard", onClick: function () { return _this.handleClick(); } }, buttonText));
    };
    ButtonCardUI.propTypes = {
        id: PropTypes.string,
        buttonState: PropTypes.string,
        buttonText: PropTypes.string.isRequired,
        routeTo: PropTypes.string,
        disable: PropTypes.bool,
        isClickable: PropTypes.bool
    };
    ButtonCardUI.defaultProps = {
        buttonText: 'Card Heading Text',
        buttonState: 'active'
    };
    return ButtonCardUI;
}(Component));
export default ButtonCardUI;
