var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Component } from 'react';
var HowItWorksContentUI = /** @class */ (function (_super) {
    __extends(HowItWorksContentUI, _super);
    function HowItWorksContentUI(props) {
        return _super.call(this, props) || this;
    }
    HowItWorksContentUI.prototype.render = function () {
        var _a = this.props, isFinal = _a.isFinal, cardHeading = _a.cardHeading, secondaryText = _a.secondaryText, iconType = _a.iconType;
        var className = isFinal ? 'enjoy-your-ride' : 'header';
        var containerClass = isFinal ? 'how-it-works-component enjoy-ride' : 'how-it-works-component';
        return (React.createElement("div", { className: containerClass },
            React.createElement("div", { className: "icon-container" },
                React.createElement("div", { className: "how-it-works-icon " + iconType })),
            React.createElement("div", { className: "card-text" },
                React.createElement("div", { className: className }, cardHeading),
                React.createElement("div", { className: "secondary" }, secondaryText))));
    };
    return HowItWorksContentUI;
}(Component));
export default HowItWorksContentUI;
