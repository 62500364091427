// externals
import * as React from 'react';
// libraries
import { dateUtils } from '@makemydeal/dr-common-utils';
// utils
import * as textFormatUtil from './textFormatUtil';
// icons
import { CheckmarkHollowIcon } from '../../icons/CheckmarkHollowIcon';
import { CheckmarkIcon } from '../../icons/CheckmarkIcon';
var getClassName = function (props) {
    var className = props.className, completionDate = props.completionDate, errorMessage = props.errorMessage;
    var result = 'dr-ui-activity-status-item';
    if (completionDate && !errorMessage) {
        result += ' dr-ui-activity-complete';
    }
    if (className) {
        result += " " + className;
    }
    return result;
};
var getFormattedStatusText = function (props) {
    var innerHtml = {
        __html: textFormatUtil.formatSimpleMarkdownAsHtml(props.statusText)
    };
    var result = React.createElement("div", { className: "dr-ui-activity-status-text", dangerouslySetInnerHTML: innerHtml });
    return result;
};
var getFormattedStatusSubText = function (props) {
    var completionDate = props.completionDate, errorMessage = props.errorMessage;
    var formattedStatusSubText = null;
    if (errorMessage) {
        formattedStatusSubText = React.createElement("div", { className: "dr-ui-activity-status-sub-text-error" }, errorMessage);
    }
    else if (completionDate) {
        var dateFormatted = dateUtils.formatAsMMDDYYYY(completionDate);
        var timeFormatted = dateUtils.formatAsTime(completionDate);
        formattedStatusSubText = React.createElement("div", { className: "dr-ui-activity-status-sub-text" }, dateFormatted + " at " + timeFormatted);
    }
    return formattedStatusSubText;
};
var ActivityStatusItemUI = function (props) {
    var icon = props.completionDate ? React.createElement(CheckmarkIcon, null) : React.createElement(CheckmarkHollowIcon, null);
    return (React.createElement("div", { className: getClassName(props) },
        React.createElement("div", { className: "dr-ui-activity-status-icon" }, icon),
        React.createElement("div", { className: "dr-ui-activity-status-content" },
            getFormattedStatusText(props),
            props && props.hideSubText ? null : getFormattedStatusSubText(props))));
};
export default ActivityStatusItemUI;
