import * as React from 'react';
import { ROUTE_DISPLAY_ADJUSTED_CASH_DOWN_BREAKDOWN } from './actions/actionTypes';
import { formatType } from '../../utils/formatUtils';
import { GroupLineItemUI, LineItemUI } from './LineItemUI';
var getLabel = function (description, key, labels) {
    switch (description) {
        case 'Advertised Price':
            return labels.advertisedPriceLabel;
        case 'Specials':
            return labels.specialsLabel;
        case 'Out of Pocket':
            // Two fields have the same description, but the first one has a different key
            return key === 'downPaymentNegative' ? labels.downPaymentLabel : labels.downPaymentLabelSecond;
        case 'Due At Signing':
            return labels.dueAtSigningLabel;
        default:
            return description;
    }
};
var ServicesSummaryUI = function (props) {
    var dealSummary = props.dealSummary, onAdjustedCashInfoClick = props.onAdjustedCashInfoClick, specialsLabel = props.specialsLabel, advertisedPriceLabel = props.advertisedPriceLabel, downPaymentLabel = props.downPaymentText, downPaymentLabelSecond = props.downPaymentTextSecond, dueAtSigningLabel = props.dueAtSigningText;
    var labels = { specialsLabel: specialsLabel, advertisedPriceLabel: advertisedPriceLabel, downPaymentLabel: downPaymentLabel, downPaymentLabelSecond: downPaymentLabelSecond, dueAtSigningLabel: dueAtSigningLabel };
    return (React.createElement("div", { className: "dr-ui-summary-container" },
        React.createElement("div", { className: "summary-column" }, dealSummary && dealSummary.map(function (item, index) {
            var notAvailableText = item.defaultValue ? item.defaultValue : '';
            var lineItemLabel = getLabel(item.description, item.key, labels);
            var formattedValue = item.displayProperties.indexOf('negative') > -1
                ? "-" + formatType(item)
                : formatType(item);
            if (item.displayProperties.indexOf('important') > -1) {
                return (React.createElement(GroupLineItemUI, { key: index, label: lineItemLabel, notAvailableText: item.defaultValue, id: item['key'], showSeparator: false, value: formatType(item) }));
            }
            if (item.displayProperties.indexOf('separator') > -1) {
                return React.createElement("hr", { key: index });
            }
            return (React.createElement(LineItemUI, { id: item['key'], key: index, label: lineItemLabel, notAvailableText: notAvailableText, onClickEvent: function () { return onAdjustedCashInfoClick(ROUTE_DISPLAY_ADJUSTED_CASH_DOWN_BREAKDOWN); }, value: formattedValue }));
        }))));
};
export default ServicesSummaryUI;
