import * as React from 'react';
/* tslint:disable:max-line-length */
export var StandardDisclaimerContentUI = function (props) {
    return (React.createElement("div", null,
        React.createElement("h5", null, "Last updated on October 11, 2019"),
        React.createElement("div", null,
            React.createElement("h3", null, "[1] My Asking Price")),
        React.createElement("div", null, "All vehicle Asking Prices are subject to final approval by the buyer and Dealer. Vehicles are subject to prior sale by Dealer if sale to buyer is not consummated."),
        React.createElement("div", null,
            React.createElement("h3", null, "[2] Incentives")),
        "\uFFFC",
        React.createElement("div", null, "Any incentives offered or applied are subject to Dealer or manufacturer restrictions and may require additional information or proof of eligibility. Incentives are subject to change and may be available for a limited time. Other terms and conditions may apply, see Dealer for details."),
        React.createElement("div", null,
            React.createElement("h3", null, "[3] Trade-in")),
        React.createElement("div", null,
            "Kelley Blue Book Trade-In Value provided as of ",
            props.currentDate,
            " Edition for ",
            props.dealerZipCode,
            ". The specific information required to determine the value for this particular vehicle was supplied by the person generating this report. Vehicle valuations may vary based upon market conditions, vehicle specifications, vehicle condition or other circumstances pertinent to this vehicle. This report is intended for the individual use of the person generating this report only and shall not be sold or transmitted to another party. Kelley Blue Book assumes no responsibility for errors or omissions. \u00A9 ",
            props.currentFullYear,
            " by Kelley Blue Book Co., Inc. All Rights Reserved."),
        React.createElement("div", null, "Negative Trade Equity (Amount Owed): This occurs when the balance owed on your trade-in vehicle exceeds the estimated value of the vehicle from the dealership. When you apply negative equity to your amount financed, the lender may have a limit to the amount you can finance. In some cases the lender may only allow you to finance part of the negative equity. An additional down payment may be required by the lender. Ask Dealer or lender for more details."),
        React.createElement("div", null,
            React.createElement("h3", null, "[4] Credit / Financing")),
        React.createElement("div", null, "All financing terms and conditions shown are estimates and actual financing terms are contingent upon completion of a credit application and credit underwriting by your lender. Annual Percentage Rates are estimates based on the credit score provided by the consumer. Your down payment, trade-in, manufacturer or dealer incentives, and any additional accessories or options may impact the Amount Financed, Capitalized Cost and final monthly payment. The financing terms vary by Lease or Loan product, Credit Score and Term. The payment and financing information provided here is not an offer or a commitment by any organization to provide credit, lease, or other financing options. Additional down payment may be required. Offers are subject to change and may be available for a limited time. Other terms and conditions may apply. Ask Dealer for details or questions about how to start your application."),
        React.createElement("div", null,
            React.createElement("h3", null, "[5] Est. Monthly Payment Range")),
        React.createElement("div", null, "Estimated payment ranges allow you to receive quotes from Dealers for a vehicle without supplying your personal information. The Estimated Monthly Payment Range is the result of estimates used to calculate the Total Amount Financed. These estimates include Taxes, Trade-In Values, Down Payment, Special Offers, Incentives, Applicable Fees, and your Credit/Financing terms.")));
};
