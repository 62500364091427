var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Component } from 'react';
import * as PropTypes from 'prop-types';
var RadioButtonGroupUI = /** @class */ (function (_super) {
    __extends(RadioButtonGroupUI, _super);
    function RadioButtonGroupUI() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RadioButtonGroupUI.prototype.handleClick = function (value) {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    };
    RadioButtonGroupUI.prototype.buildRadioButtonGroup = function (itemArray, name, selectedItem) {
        var _this = this;
        return itemArray.map(function (item, index) {
            var unlimitedMiles = item === 'Unlimited' ? 'unlimited-miles' : '';
            var checked = '';
            if ((selectedItem !== null) && (selectedItem === item)) {
                checked = 'checked';
            }
            return (React.createElement("div", { className: "radio-button " + checked + " " + unlimitedMiles, onClick: function () { _this.handleClick(item); }, key: _this.props.cardId + "-" + name + "-" + index },
                React.createElement("input", { type: "radio", id: _this.props.cardId + "-" + name + "-" + index, name: _this.props.cardId + "-" + name, value: item }),
                React.createElement("label", { htmlFor: _this.props.cardId + "-" + name + "-" + index }, item)));
        });
    };
    RadioButtonGroupUI.prototype.render = function () {
        var _a = this.props, itemArray = _a.itemArray, name = _a.name, selectedItem = _a.selectedItem;
        var getRadioButtonGroup = this.buildRadioButtonGroup(itemArray, name, selectedItem);
        return (React.createElement("div", { className: "radio-button-group" }, getRadioButtonGroup));
    };
    RadioButtonGroupUI.propTypes = {
        itemArray: PropTypes.array,
        name: PropTypes.string,
        cardId: PropTypes.string,
        selectedItem: PropTypes.string
    };
    return RadioButtonGroupUI;
}(Component));
export default RadioButtonGroupUI;
