var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as React from 'react';
import { Component } from 'react';
import * as PropTypes from 'prop-types';
// tslint:disable-next-line:import-name
import ReactImageFallback from 'react-image-fallback';
var MenuProductImage = /** @class */ (function (_super) {
    __extends(MenuProductImage, _super);
    function MenuProductImage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MenuProductImage.prototype.render = function () {
        var _a = this.props, imageUrl = _a.imageUrl, imageAltText = _a.imageAltText, imagePlaceHolder = _a.imagePlaceHolder;
        return (React.createElement(ReactImageFallback, { src: imageUrl, alt: imageAltText, fallbackImage: imagePlaceHolder }));
    };
    MenuProductImage.propTypes = {
        imageUrl: PropTypes.string,
        imageAltText: PropTypes.string
    };
    MenuProductImage.defaultProps = {
        imageUrl: '',
        imagePlaceHolder: 'https://247windscreens.co.uk/wp-content/uploads/2014/06/Bills_to_pay_icons-07-5121.png',
        imageAltText: 'image'
    };
    return MenuProductImage;
}(Component));
export default MenuProductImage;
