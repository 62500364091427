import * as numeral_ from 'numeral';
var numeral = numeral_;
export function roundDollar(dollars) {
    return Math.ceil(dollars);
}
export function formatDollars(dollars) {
    if (dollars === null || isNaN(dollars)) {
        return '$--';
    }
    return numeral(roundDollar(dollars)).format('$0,000');
}
export function isValidNumber(num) {
    return !(num === null || num === undefined || isNaN(num));
}
export function isValidDollarAmount(dollars) {
    return isValidNumber(dollars);
}
export function invalidDollarDisplayValue() {
    return '$--';
}
export function formatNumber(num) {
    return numeral(num).format('0,000');
}
export function formatRetailPrice(price) {
    if (price === 0) {
        return invalidDollarDisplayValue();
    }
    else {
        return formatCurrencyAmount(price);
    }
}
export function formatTermMonths(value, addSlash) {
    if (addSlash === void 0) { addSlash = false; }
    if (value) {
        return "" + value + (addSlash ? '/' : ' ') + "mo";
    }
    else {
        return null;
    }
}
export function formatApr(value) {
    return value + "% APR";
}
export function formatFees(value) {
    if (!isValidDollarAmount(value)) {
        return 'Not Included';
    }
    return formatDollars(value);
}
export function formatMiles(value) {
    var formattedNumber = formatNumber(value);
    return formattedNumber + " mi";
}
export function formatCurrencyAmount(amount) {
    return isValidDollarAmount(amount) ? formatDollars(amount) : invalidDollarDisplayValue();
}
export function formatType(item) {
    switch (item.type) {
        case 'money':
            return formatFees(item.value);
        case 'apr':
            return formatApr(item.value);
        case 'months':
            return formatTermMonths(item.value);
        case 'miles':
            return formatMiles(item.value);
        default:
            return item.value;
    }
}
