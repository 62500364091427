import * as React from 'react';
import { StandardDisclaimerContentUI } from './content/StandardDisclaimerUI';
import { HertzDisclaimerContentUI } from './content/HertzDisclaimerUI';
// NOTE: In future this could be exported from this component if it is needed elsewhere
var HERTZ_TEMPLATE_NAME = 'hertz';
var DisclaimerUI = function (props) {
    var customLongDisclaimer = props.customLongDisclaimer;
    var dealerDisclaimer = customLongDisclaimer ? (React.createElement("div", null,
        React.createElement("h4", null, "Dealer Disclaimer"))) : null;
    var DisclaimerContentUI = props.disclaimerTemplateName === HERTZ_TEMPLATE_NAME ?
        React.createElement(HertzDisclaimerContentUI, null) : React.createElement(StandardDisclaimerContentUI, { dealerZipCode: props.dealerZipCode, currentDate: props.currentDate, currentFullYear: props.currentFullYear });
    return (React.createElement("div", { className: "Disclaimer-container" },
        React.createElement("h1", { className: "dr-ui-legal-docs-headline" }, "Disclaimers"),
        dealerDisclaimer,
        React.createElement("div", { className: "custom-disclaimer-long" }, customLongDisclaimer),
        DisclaimerContentUI));
};
export default DisclaimerUI;
